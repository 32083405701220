import React from 'react';
import { fetchReportsBookings } from 'store/actions';

// Import columns
import { columns } from './columns';

// Import components
import { TableExportProvider } from 'components/context';
import { TableExportButtons, TableExport } from 'components/elements';
import ExportFilters from '../ExportFilters';

const Export = () => {
	return (
		<TableExportProvider
			columns={columns}
			fetchDataTableAction={fetchReportsBookings}
		>
			<TableExportButtons />
			<TableExport renderFilters={() => <ExportFilters />} />
		</TableExportProvider>
	);
};

export default Export;
