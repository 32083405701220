import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, Grid, Tooltip, Chip } from '@material-ui/core';

// Import helpers
import { CUSTOMER_TYPES, VERIFY_TYPES } from 'helpers';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import images
import { elmLogo } from 'assets/images';

// Import styles
import useStyles from './styles';

const NoteTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { type, verify_type } = cell.row.original;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container direction="column" spacing={1}>
				{verify_type === VERIFY_TYPES.ELM && (
					<Grid item>
						<Tooltip title={t('bookings.table.notes.verified_by_elm')}>
							<img className={classes.image} src={elmLogo} alt="Elm" />
						</Tooltip>
					</Grid>
				)}

				{type === CUSTOMER_TYPES.TRUSTED && (
					<Grid item>
						<Tooltip title={t('common.icon_names.vip')}>
							<Chip
								className={classes.chip}
								color="primary"
								size="small"
								label="VIP"
							/>
						</Tooltip>
					</Grid>
				)}
			</Grid>
		</TableCell>
	);
};

NoteTableCell.propTypes = {
	cell: PropTypes.object.isRequired,
};

export default NoteTableCell;
