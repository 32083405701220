import React from 'react';
import { useSelector } from 'react-redux';
import { object } from 'prop-types';
import { TableCell, TableRow, Typography } from '@material-ui/core';

// Import utils and helpers
import { replaceCorporateBookingsUrl, replacePreviewBookingUrl } from 'helpers';
import { useTranslations } from 'components/utilities';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { ActionsTableCell, CarNameTableCell } from '../../components';
import {
	CustomerNameTableCell,
	SettlementTableCell,
	RatingTableCell,
	LinkTableCell,
} from 'components/elements';

// Import store
import { profileDataSelector } from 'store/selectors';

export const TableBodyRow = ({ row }) => {
	const { t } = useTranslations();
	const { corporate } = useSelector(profileDataSelector);

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			user_rating: userRating,
			uuid: customerUuid,
			phone: phoneNumber,
			last_booking,
			last_limit,
			full_name,
		} = cell.row.original || {};

		const { used_amount_gross: bookingAmount } = last_limit || {};

		const {
			individual_number: lastBookingIndividualNumber,
			created_at: createdAt,
			pick_date: pickDate,
			uuid: bookingUuid,
			is_settled: isSettled,
		} = last_booking || {};

		const getBookingsListUrl = () => {
			const baseUrl = replaceCorporateBookingsUrl(corporate.uuid);
			return `${baseUrl}?customerUuid=${customerUuid}`;
		};

		const bookingListUrl = getBookingsListUrl();

		switch (cell.column.id) {
			case COLUMNS_ACCESSORS.ACTIONS:
				return <ActionsTableCell key={cell.column.id} cell={cell} />;

			case COLUMNS_ACCESSORS.CUSTOMER_NAME:
				return (
					<CustomerNameTableCell
						key={cell.column.id}
						cell={cell}
						customerName={full_name}
						link={bookingListUrl}
						phoneNumber={phoneNumber}
					/>
				);

			case COLUMNS_ACCESSORS.SETTLEMENT:
				return (
					<SettlementTableCell
						isSettled={isSettled}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case COLUMNS_ACCESSORS.CREATED_AT:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{createdAt && <Typography variant="h6">{createdAt}</Typography>}
					</TableCell>
				);

			case COLUMNS_ACCESSORS.PICK_DATE:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{pickDate && (
							<Typography noWrap variant="h6">
								{pickDate}
							</Typography>
						)}
					</TableCell>
				);

			case COLUMNS_ACCESSORS.LAST_BOOKING_ID:
				return (
					<LinkTableCell
						to={replacePreviewBookingUrl(bookingUuid)}
						key={cell.column.id}
						cell={cell}
					>
						{lastBookingIndividualNumber}
					</LinkTableCell>
				);

			case COLUMNS_ACCESSORS.RATING:
				return (
					<RatingTableCell
						key={cell.column.id}
						cell={cell}
						rating={userRating}
					/>
				);

			case COLUMNS_ACCESSORS.BOOKING_AMOUNT:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{!!bookingAmount && (
							<Typography variant="h6">
								{bookingAmount} {t('currency.sar')}
							</Typography>
						)}
					</TableCell>
				);

			case COLUMNS_ACCESSORS.CAR_NAME:
				return <CarNameTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
