import * as types from 'store/types';
import services from 'services/services';
import {
	phonePrefixUrl,
	fetchBusinessManagerProfileUrl,
	fetchBusinessCompanyCollectionUrl,
	fetchProfileFormResourcesUrl,
	submitProfileFormUrl,
} from 'store/paths';

// Import helpers
import { convertRoleToDisplay, OFFICE_MANAGER, COMPANY_MANAGER } from 'helpers';
import {
	initialValues,
	formatUserData,
	findCompanyUuid,
	convertDataToStore,
} from './helpers';
import { catchError } from '../helpers';

const {
	COMPANY_MANAGER_FORM_FETCH_DATA_LOADING,
	COMPANY_MANAGER_FORM_FETCH_DATA_SUCCESS,
	COMPANY_MANAGER_FORM_ADD_USER_SUCCESS,
	COMPANY_MANAGER_FORM_FETCH_DATA_ERROR,
	PROFILE_FORM_FETCH_DATA_LOADING,
	PROFILE_FORM_FETCH_DATA_SUCCESS,
	PROFILE_FORM_FETCH_DATA_ERROR,
} = types;

export const fetchProfileFormResources =
	({ itemId, isEdit, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: COMPANY_MANAGER_FORM_FETCH_DATA_LOADING,
			});

			const phonePrefixesPromise = services.get(phonePrefixUrl, cancelToken);

			const companyListPromise = services.get(
				fetchBusinessCompanyCollectionUrl,
				cancelToken
			);

			const userDataPromise = services.get(
				fetchProfileFormResourcesUrl(itemId),
				cancelToken
			);

			const values = await Promise.all([
				phonePrefixesPromise,
				companyListPromise,
				userDataPromise,
			]);

			const [
				{
					data: { data: phonePrefixes },
				},
				{
					data: { data: companyList },
				},
				{
					data: { data: userData },
				},
			] = values;

			const { manager, belonging } = userData;
			const { userCompanyUuid } = findCompanyUuid(manager.role, belonging);

			const roles = [OFFICE_MANAGER, COMPANY_MANAGER].map((role) => ({
				key: role,
				label: convertRoleToDisplay(role),
			}));

			const { belonging: initialBelonging } = initialValues;

			const additionalData = convertDataToStore({
				isEdit,
				userData,
				initialBelonging,
				userCompanyUuid,
			});

			const actionType = isEdit
				? COMPANY_MANAGER_FORM_FETCH_DATA_SUCCESS
				: COMPANY_MANAGER_FORM_ADD_USER_SUCCESS;
			const payload = { phonePrefixes, companyList, roles, ...additionalData };

			dispatch({
				type: actionType,
				payload,
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: COMPANY_MANAGER_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitProfileForm =
	({ values, callbackSuccess, errorCallback, isEdit }) =>
	async () => {
		try {
			const { userData, userUuid } = formatUserData({
				values,
			});

			await services.post(submitProfileFormUrl(isEdit, userUuid), userData);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const getProfile = async (dispatch) => {
	try {
		dispatch({
			type: PROFILE_FORM_FETCH_DATA_LOADING,
		});

		const {
			data: { data: userData },
		} = await services.get(fetchBusinessManagerProfileUrl);

		dispatch({
			type: PROFILE_FORM_FETCH_DATA_SUCCESS,
			payload: userData,
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: PROFILE_FORM_FETCH_DATA_ERROR,
		});
	}
};
