import React from 'react';
import { object, string } from 'prop-types';
import { Typography, Box, Chip } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useSteps } from './useSteps';

// Import styles
import { useStyles } from './Steps.styles';

// Import components
import { LocationContainer, Statistics } from './components';

export const Steps = ({ deliveryType, customDeliveryData }) => {
	const { t } = useTranslations();

	const { isChipLabelVisible, isChipLabelRed, getChipLabel, driverName } =
		useSteps({ deliveryType, customDeliveryData });

	const classes = useStyles();

	return (
		<Box className={classes.extraWrapper}>
			<Box className={classes.innerWrapper} key={deliveryType}>
				<Box className={classes.header}>
					<Box className={classes.heading}>
						<Typography variant="h5">
							{t(`bookings.preview_booking.car.assigned_to_${deliveryType}`)}
						</Typography>
						{isChipLabelVisible(deliveryType) && (
							<Chip
								label={t(getChipLabel(deliveryType))}
								className={
									isChipLabelRed(deliveryType)
										? classes.redChip
										: classes.greenChip
								}
								color="primary"
								size="small"
							/>
						)}
					</Box>
					<Typography variant="h6">{driverName}</Typography>
				</Box>

				<LocationContainer
					deliveryType={deliveryType}
					customDeliveryData={customDeliveryData}
				/>

				<Statistics
					deliveryType={deliveryType}
					customDeliveryData={customDeliveryData}
				/>
			</Box>
		</Box>
	);
};

Steps.propTypes = {
	deliveryType: string,
	customDeliveryData: object,
};
