import React, { useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import DateFnsUtils from '@date-io/date-fns';
import { addDays } from 'date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, Checkbox, Grid, InputLabel, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'lodash';

// Import utils
import { useTranslations } from 'components/utilities';

// Import helpers
import { DEFAULT_DATE_FORMAT, FIELD_TYPES, YMDHM_DATE_FORMAT } from 'helpers';

// Import components
import {
	DatePickerFieldWithIconAdapter,
	MultiselectFiledAdapter,
	DateAndTimePickerFieldAdapter,
	CheckboxFieldAdapter,
	SelectFieldAdapter,
	TextFieldAdapter,
	SwitchFieldAdapter,
	FileFieldAdapter,
	FileUploader,
	PhoneNumberFieldAdapter,
	AutocompleteFieldAdapter,
	DateSelectFieldAdapter,
	CarPlateNumberFieldAdapter,
	MultiSelectFieldWithSearchAdapter,
	DraftWysiwygFieldAdapter,
} from 'components/elements';

// Import styles
import { useStyles } from './styles';

const {
	CHECKBOX,
	DATE,
	SELECT,
	DATE_AND_TIME,
	SWITCH,
	IMAGE_FILE,
	FILE_UPLOADER,
	PHONE,
	AUTOCOMPLETE,
	DATE_SELECT,
	PLATE_NUMBER,
	MULTI_SELECT,
	MULTI_SELECT_FILTER,
	MULTI_SELECT_WITH_SEARCH,
	MULTI_SELECT_WITH_SEARCH_FILTER,
	RICH_TEXT,
} = FIELD_TYPES;

export const useCommonFields = () => {
	const [isAllOptionChecked, setIsAllOptionChecked] = useState(false);
	const { submitting, values } = useFormState();
	const { t } = useTranslations();
	const { change } = useForm();
	const classes = useStyles();

	const defaultMinDate = addDays(new Date(), 1);

	const renderFields = (field) => {
		const {
			minDate = defaultMinDate,
			maxDate,
			labelExtractor,
			keyExtractor,
			required,
			label,
			type,
			name,
			md = 12,
			xs = 12,
			disabled,
			options,
			isHidden,
			rows,
			multiline,
			placeholder,
			inputLabelProps,
			previewFileState,
			fileNameField,
			currentMultiSelectValues,
			multiSelectLabelExtractor,
			showAllOption,
			removeUpAndDownArrows,
			handleOnFileUploader,
			fileUploaderError,
			startAdornment,
			endAdornment,
			prefixName,
			filterOptions,
			handleOnCheckboxClick = () => {},
			shouldDefaultHijri,
			...rest
		} = field;

		const handleOnMultiSelectFilterChange = (event) => {
			const { value, name } = event.target;

			const filteredValue = value.filter((value) => null != value);
			const currentValue = filteredValue.at(-1);

			if (currentValue === 'all') {
				if (filteredValue.includes('all')) {
					change(name, ['all']);
				} else {
					change(name, []);
				}
			} else {
				if (filteredValue.includes('all')) {
					change(
						name,
						filteredValue.filter((value) => value !== 'all')
					);
				} else {
					change(name, filteredValue);
				}
			}
		};

		const handleOnMultiSelectChange = (event) => {
			const { value, name } = event.target;

			const filteredValue = value.filter((value) => null != value);

			change(name, filteredValue);
		};

		const handleOnAllOptionChange = () => {
			setIsAllOptionChecked((prev) => !prev);

			if (isAllOptionChecked) {
				change(name, []);
			} else {
				const allUuids = Object.values(options).map(({ uuid }) => uuid);
				change(name, allUuids);
			}
		};

		const isChecked = (option) =>
			currentMultiSelectValues?.includes(keyExtractor(option));

		if (isHidden) return null;

		switch (type) {
			case SELECT:
				return (
					<Grid key={name} item md={md} xs={xs}>
						<Field
							fullWidth
							component={SelectFieldAdapter}
							type={type}
							label={t(label)}
							name={name}
							id={name}
							margin="dense"
							variant="outlined"
							disabled={submitting || disabled}
							required={required}
							inputProps={rest}
							defaultValue={rest?.defaultValue}
						>
							{Object.values(options).map((option) => (
								<MenuItem
									key={keyExtractor(option)}
									value={keyExtractor(option)}
								>
									{labelExtractor(option)}
								</MenuItem>
							))}
						</Field>
					</Grid>
				);

			// for common multi selects
			case MULTI_SELECT:
			case MULTI_SELECT_WITH_SEARCH: {
				const convertedOptions = Object.values(options);
				const namedValues = get(values, name);
				const areAllOptionsSelected =
					convertedOptions?.length === namedValues?.length;
				if (!areAllOptionsSelected && isAllOptionChecked === true) {
					setIsAllOptionChecked(false);
				}

				return (
					<Grid key={name} item md={md} xs={xs}>
						<Field
							format={(value) => (Array.isArray(value) ? value : [])}
							label={t(label)}
							component={
								type === MULTI_SELECT_WITH_SEARCH
									? MultiSelectFieldWithSearchAdapter
									: MultiselectFiledAdapter
							}
							disabled={submitting || disabled}
							labelextractor={(option) =>
								multiSelectLabelExtractor(convertedOptions, option)
							}
							variant="outlined"
							margin="dense"
							type="select"
							name={name}
							fullWidth
							required={required}
							onChange={handleOnMultiSelectChange}
							displayEmpty={true}
							smallfieldstyle={rest?.smallFieldStyle}
							emptyLabel={rest?.emptyLabel}
							searchExtractor={rest?.searchExtractor}
						>
							{showAllOption && (
								<Box component="label" htmlFor="all">
									<Box key="all" className={classes.allOption}>
										<Checkbox
											onChange={handleOnAllOptionChange}
											checked={isAllOptionChecked}
											color="primary"
											id="all"
										/>
										{t('common.all')}
									</Box>
								</Box>
							)}
							{convertedOptions.map((option) => (
								<MenuItem
									key={keyExtractor(option)}
									value={keyExtractor(option)}
								>
									<Checkbox checked={isChecked(option)} color="primary" />
									{labelExtractor(option)}
								</MenuItem>
							))}
						</Field>
					</Grid>
				);
			}

			// only for filters
			case MULTI_SELECT_FILTER:
			case MULTI_SELECT_WITH_SEARCH_FILTER: {
				const sortedOptions = Object.values(options).sort((a, b) =>
					isChecked(a) === isChecked(b) ? 0 : isChecked(a) ? -1 : 1
				);
				const convertedOptions = showAllOption
					? Object.values(['all', ...sortedOptions])
					: Object.values(sortedOptions);

				return (
					<Grid key={name} item md={md} xs={xs}>
						<Field
							format={(value) => (Array.isArray(value) ? value : [])}
							label={t(label)}
							component={
								type === MULTI_SELECT_WITH_SEARCH_FILTER
									? MultiSelectFieldWithSearchAdapter
									: MultiselectFiledAdapter
							}
							disabled={submitting || disabled}
							labelextractor={(option) =>
								multiSelectLabelExtractor(convertedOptions, option)
							}
							variant="outlined"
							margin="dense"
							type="select"
							name={name}
							fullWidth
							required={required}
							onChange={handleOnMultiSelectFilterChange}
							displayEmpty={true}
							smallfieldstyle={rest?.smallFieldStyle}
							emptyLabel={rest?.emptyLabel}
							searchExtractor={rest?.searchExtractor}
						>
							{convertedOptions.map((option) => (
								<MenuItem
									key={option === 'all' ? 'all' : keyExtractor(option)}
									value={option === 'all' ? 'all' : keyExtractor(option)}
								>
									<Checkbox
										checked={currentMultiSelectValues?.includes(
											option === 'all' ? 'all' : keyExtractor(option)
										)}
										color="primary"
									/>
									{option === 'all' ? t('common.all') : labelExtractor(option)}
								</MenuItem>
							))}
						</Field>
					</Grid>
				);
			}

			case DATE:
				return (
					<Grid key={name} item md={md} xs={xs}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Field
								fullWidth
								component={DatePickerFieldWithIconAdapter}
								type="text"
								name={name}
								label={t(label)}
								margin="dense"
								inputVariant="outlined"
								disabled={submitting || disabled}
								pickerFormat={DEFAULT_DATE_FORMAT}
								minDate={minDate}
								maxDate={maxDate}
								required={required}
								inputProps={rest}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				);

			case DATE_AND_TIME:
				return (
					<Grid key={name} item md={md} xs={xs}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Field
								fullWidth
								component={DateAndTimePickerFieldAdapter}
								type="text"
								name={name}
								label={t(label)}
								margin="dense"
								inputVariant="outlined"
								disabled={submitting || disabled}
								pickerFormat={YMDHM_DATE_FORMAT}
								minDate={minDate}
								required={required}
								inputProps={rest}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				);

			case CHECKBOX:
				return (
					<Grid container alignItems="flex-end" key={name} item md={md} xs={xs}>
						<Grid item>
							<Field
								component={CheckboxFieldAdapter}
								type="checkbox"
								labelText={t(label)}
								name={name}
								disabled={submitting || disabled}
								color="primary"
								required={required}
								inputProps={rest}
								onClick={handleOnCheckboxClick}
							/>
						</Grid>
					</Grid>
				);

			case SWITCH:
				return (
					<Grid container alignItems="flex-end" key={name} item md={md} xs={xs}>
						<Grid item>
							<Field
								component={SwitchFieldAdapter}
								type="checkbox"
								labelText={t(label)}
								name={name}
								disabled={submitting || disabled}
								color="primary"
								required={required}
								inputProps={rest}
							/>
						</Grid>
					</Grid>
				);

			case IMAGE_FILE:
				return (
					<Grid item md={md} xs={xs} key={name}>
						<Field
							previewFileState={previewFileState}
							component={FileFieldAdapter}
							name={name}
							fileNameField={fileNameField}
							disabled={submitting}
							label={label}
							required={required}
						/>
					</Grid>
				);

			case FILE_UPLOADER:
				return (
					<Grid key={label} item xs={xs} md={md}>
						<FileUploader
							label={label}
							onSuccessCallback={handleOnFileUploader}
							error={fileUploaderError}
						/>
					</Grid>
				);

			case PHONE:
				return (
					<Grid key={label} item xs={xs} md={md}>
						<PhoneNumberFieldAdapter
							prefixName={prefixName}
							phoneNumberName={name}
							required={required}
							label={label}
						/>
					</Grid>
				);

			case AUTOCOMPLETE:
				return (
					<Grid key={name} item md={md} xs={xs}>
						<Field
							fullWidth
							component={AutocompleteFieldAdapter}
							type="text"
							label={t(label)}
							name={name}
							id={name}
							margin="dense"
							variant="outlined"
							disabled={submitting || disabled}
							required={required}
							inputProps={rest}
							options={options}
							filterOptions={filterOptions}
						/>
					</Grid>
				);

			case DATE_SELECT:
				return (
					<Grid key={name} item md={md} xs={xs}>
						<Field
							fullWidth
							key={name}
							component={DateSelectFieldAdapter}
							type="text"
							label={t(label)}
							name={name}
							id={name}
							margin="dense"
							variant="outlined"
							disabled={submitting || disabled}
							required={required}
							inputProps={rest}
							shouldDefaultHijri={shouldDefaultHijri}
						/>
					</Grid>
				);

			case PLATE_NUMBER:
				return (
					<Grid key={name} item md={md} xs={xs}>
						<Field
							key={name}
							component={CarPlateNumberFieldAdapter}
							name={name}
							{...rest}
						/>
					</Grid>
				);

			case RICH_TEXT:
				return (
					<Grid key={name} item md={md} xs={xs}>
						<InputLabel required={required}>{t(label)}</InputLabel>
						<Field
							key={name}
							component={DraftWysiwygFieldAdapter}
							name={name}
							required={required}
							{...rest}
						/>
					</Grid>
				);

			default:
				return (
					<Grid key={name} item md={md} xs={xs}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type={type}
							label={t(label)}
							name={name}
							id={name}
							margin="dense"
							variant="outlined"
							disabled={submitting || disabled}
							required={required}
							rows={rows}
							multiline={multiline}
							placeholder={placeholder}
							inputProps={rest}
							InputProps={{
								startAdornment,
								endAdornment,
							}}
							InputLabelProps={inputLabelProps}
							className={clsx({
								[classes.removeUpAndDownArrows]: removeUpAndDownArrows,
								[rest?.className]: rest?.className,
							})}
						/>
					</Grid>
				);
		}
	};

	return { renderFields };
};
