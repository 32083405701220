const { makeStyles } = require('@material-ui/styles');

const useStyles = makeStyles((theme) => ({
	chip: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
	},
	image: {
		width: '30px',
	},
}));

export default useStyles;
