import React, { useEffect, useState } from 'react';
import { object, node, string, func, oneOfType, bool } from 'prop-types';
import {
	InputLabel,
	Select,
	FormControl,
	OutlinedInput,
	Box,
	Typography,
	Tooltip,
	Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import { get, slice } from 'lodash';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { MenuProps } from './helpers';

// Import components
import { InputError } from 'components/elements';
import { SearchInput } from './components';

// Import styles
import { useStyles } from './MultiSelectFieldWithSearchAdapter.styles';
import { useSharedStyles } from 'theme/styles/shared';

export const MultiSelectFieldWithSearchAdapter = ({
	input,
	label,
	meta,
	children,
	labelextractor,
	smallfieldstyle = false,
	emptyLabel = '',
	searchExtractor = 'children',
	...rest
}) => {
	const [filteredChildren, setFilteredChildren] = useState(children);
	const [searchValue, setSearchValue] = useState('');
	const sharedClasses = useSharedStyles();
	const [open, setOpen] = useState(false);
	const { t } = useTranslations();
	const classes = useStyles();

	const error = !!(meta.touched && (meta.error || meta.submitError));

	const labelExtractor = labelextractor ? labelextractor : (value) => value;

	const handleOnSearch = (e) => {
		const searchValue = e.target.value;
		setSearchValue(searchValue);
	};

	const getLabelsCounter = (selected) => `+${selected?.length - 3}`;
	const getComma = (index) => (index === 0 ? '' : ', ');
	const getModifiedOption = (selected) =>
		slice(selected, 0, 3)
			.map((value) => labelExtractor(value))
			.join(', ');

	useEffect(() => {
		const truthyChildren = children.filter((child) => child);

		const filteredChildren = truthyChildren
			?.flat()
			?.filter(({ props }) =>
				get(props, searchExtractor, '')
					?.toLowerCase()
					?.includes(searchValue.toLowerCase())
			);

		setFilteredChildren(filteredChildren);
		// eslint-disable-next-line
	}, [searchValue, children]);

	return (
		<FormControl error={error} className={classes.formControl}>
			{label && (
				<InputLabel id="mutiple-chip-label" required={rest?.required}>
					{t(label)}
				</InputLabel>
			)}
			<Select
				{...input}
				{...rest}
				className={smallfieldstyle ? sharedClasses.smallField : ''}
				labelId="mutiple-chip-label"
				multiple
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				MenuProps={MenuProps}
				input={
					<OutlinedInput
						className={clsx({
							[classes.openOutlinedInput]: open,
							[classes.outlinedInput]: true,
						})}
					/>
				}
				renderValue={(selected) => (
					<Box className={classes.labels}>
						{!selected.length && emptyLabel}
						{selected.length <= 3 ? (
							selected.map((value, index) => (
								<Typography className={classes.selectedValue} key={value}>
									{value === 'all'
										? t('common.all')
										: `${getComma(index)} ${labelExtractor(value)}`}
								</Typography>
							))
						) : (
							<Tooltip
								title={selected
									.map((value) => labelExtractor(value))
									.join(', ')}
							>
								<Grid container spacing={1}>
									<Grid item xs="auto">
										<Typography className={classes.selectedValue}>
											{getModifiedOption(selected)}
										</Typography>
									</Grid>
									<Grid item xs="auto">
										<Typography className={classes.selectedValue}>
											{getLabelsCounter(selected)}
										</Typography>
									</Grid>
								</Grid>
							</Tooltip>
						)}
					</Box>
				)}
			>
				<SearchInput onChange={handleOnSearch} />
				{filteredChildren}
			</Select>
			<InputError meta={meta} />
		</FormControl>
	);
};

MultiSelectFieldWithSearchAdapter.propTypes = {
	label: string.isRequired,
	input: object.isRequired,
	meta: object.isRequired,
	children: node.isRequired,
	labelextractor: func,
	smallfieldstyle: oneOfType([bool, string]),
	emptyLabel: string,
	searchExtractor: string,
};
