import { combineQueryAndPaginationParams } from 'helpers';
import services from 'services/services';
import {
	fetchLastBookingUrl,
	bookingCancelReasonsUrl,
	updateStatusLastBookingUrl,
} from 'store/paths';

export const fetchLastBooking =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const lastBookingsPromise = services.get(
				fetchLastBookingUrl(params),
				cancelToken
			);

			const cancelReasonsPromise = services.get(
				bookingCancelReasonsUrl,
				cancelToken
			);

			const values = await Promise.all([
				lastBookingsPromise,
				cancelReasonsPromise,
			]);

			const [{ data }, { data: cancelReasons }] = values;

			const resources = { cancelReasons: cancelReasons.data };

			return Promise.resolve({ data, resources });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateStatusLastBooking =
	({ isAccepted, id, cancelId }) =>
	async () => {
		try {
			const deleteOption = {
				cancel_reason: {
					uuid: cancelId,
				},
			};
			const options = isAccepted ? deleteOption : {};

			// Get response
			await services.put(updateStatusLastBookingUrl(id, isAccepted), options);
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	};
