import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { BOOKING_STATES_QUERY } from 'helpers';

const useBookingStates = () => {
	const path = `v2/booking/states`;

	const { data, isFetching } = useQuery(
		BOOKING_STATES_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data?.data ?? [];

	return { data: fetchedData, isFetching };
};

export default useBookingStates;
