import { combineQueryAndPaginationParams } from 'helpers';
import services from 'services/services';
import {
	bookingCancelReasonsUrl,
	fetchTransferredBookingsUrl,
	bookingStatesUrl,
} from 'store/paths';

export const fetchTransferredBookings =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const transferredBookingsPromise = services.get(
				fetchTransferredBookingsUrl(params),
				cancelToken
			);

			const cancelReasonsPromise = services.get(
				bookingCancelReasonsUrl,
				cancelToken
			);

			const bookingStatesPromise = services.get(bookingStatesUrl, cancelToken);

			const values = await Promise.all([
				transferredBookingsPromise,
				cancelReasonsPromise,
				bookingStatesPromise,
			]);

			const [{ data }, { data: cancelReasons }, { data: states }] = values;

			const resources = {
				cancelReasons: cancelReasons.data,
				states: states.data,
			};

			return Promise.resolve({ data, resources });
		} catch (error) {
			return Promise.reject(error);
		}
	};
