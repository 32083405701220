import services from 'services/services';
import { fetchBusinessManagerProfileUrl } from 'store/paths';

export const getCompanyProfile = async (cancelToken) => {
	const { data } = await services.get(
		fetchBusinessManagerProfileUrl,
		cancelToken
	);
	return data.data;
};

export const getCompanyUuid = async (cancelToken) => {
	const {
		belonging: {
			company: { uuid },
		},
	} = await getCompanyProfile(cancelToken);

	return uuid;
};

export const getHolidays = async ({
	uuid,
	isConverted = true,
	cancelToken,
}) => {
	const companyUuid = uuid || (await getCompanyUuid(cancelToken));
	const url = `/dashboard/company/public-holidays/list?company_uuids[0]=${companyUuid}`;
	const { data: holidays } = await services.get(url, cancelToken);

	const convertedHolidays =
		holidays &&
		Object.entries(holidays).map(([name, { date_from, date_to }]) => ({
			selected: false,
			date_from,
			date_to,
			name,
		}));

	return isConverted ? convertedHolidays : holidays;
};

export const refreshHolidays = async ({
	isConverted = false,
	companyUuid,
	cancelToken,
	dispatch,
	type,
}) => {
	const payload = await getHolidays({
		uuid: companyUuid,
		isConverted,
		cancelToken,
	});

	dispatch({
		type,
		payload,
	});
};
