import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CAR_YEARS_QUERY } from 'helpers';

export const useVehicleYearList = ({ body = {}, enabled }) => {
	const path = '/v2/supplier/vehicle/year/list';

	const { data, isFetching } = useQuery(
		[CAR_YEARS_QUERY, JSON.stringify(body)],
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};
