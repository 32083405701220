import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { URGENT_BOOKINGS_STATS_QUERY } from 'helpers';

export const useUrgentBookingsStats = (options = {}) => {
	const path = 'v2/booking/operation/urgent/stats';

	const { data, isLoading, isStale, isError, error, refetch } = useQuery(
		[URGENT_BOOKINGS_STATS_QUERY, JSON.stringify(options)],
		async () => await services.post(path, options),
		{ refetchOnWindowFocus: false, staleTime: 1000 }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isLoading, isError, error, refetch, isStale };
};
