import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import utilities
import { useMediaQuerySizes, useTranslations } from 'components/utilities';

// Import helpers
import { findTitles, getPromoCodeSectionName } from './helpers';
import { nav_links } from '../Sidebar/helpers/nav_links';
import {
	replaceCreateContractUrl,
	replaceExtendContractUrl,
	replaceCloseContractUrl,
	replaceContractUrl,
	replaceSuspendContractUrl,
	replaceEditCorporateUrl,
	replaceEditCorporateCustomerUrl,
	replaceCorporateSettlementsUrl,
	replaceUnsettledBookingsUrl,
	replaceExternalPartnersListUrl,
	replaceLeasingCarsUrl,
	replaceAddLeasingCarUrl,
	replaceEditLeasingCarUrl,
} from 'helpers';
import { URLS } from 'components/routes';

export const useTopbar = () => {
	const { seoTypes } = useSelector((store) => store.defaultSeo.seoTypes);
	const { pathname: fullLocation } = useLocation();
	const { t } = useTranslations();
	const { id, officeId, vehicleId } = useParams();
	const { isTabletSize } = useMediaQuerySizes();

	const getSectionName = () => {
		const nav = nav_links(seoTypes);
		const titlesArray = findTitles(nav, fullLocation);

		let sectionName = titlesArray.map(t).join(' / ');

		if (sectionName) {
			if (fullLocation.includes('add')) sectionName += ` / ${t('common.add')}`;

			if (fullLocation.includes('edit'))
				sectionName += ` / ${t('common.edit')}`;

			if (fullLocation.includes('preview'))
				sectionName += ` / ${t('common.preview')}`;

			if (fullLocation.includes('details'))
				sectionName += ` / ${t('common.details')}`;
		} else {
			if (fullLocation.includes('customer') && fullLocation.includes('preview'))
				sectionName = `${t('common.customer')} / ${t('common.preview')}`;

			if (fullLocation.includes('customer') && fullLocation.includes('edit'))
				sectionName = `${t('common.customer')} / ${t('common.edit')}`;
		}

		// contract sections
		if (fullLocation === replaceCreateContractUrl(id)) {
			sectionName = t('bookings.single_booking.details.initiate_contract');
		}
		if (fullLocation === replaceExtendContractUrl(id)) {
			sectionName = t('bookings.single_booking.details.extend_contract');
		}
		if (fullLocation === replaceCloseContractUrl(id)) {
			sectionName = t('bookings.single_booking.details.close_contract');
		}
		if (fullLocation === replaceContractUrl(id)) {
			sectionName = t('bookings.single_booking.details.view_contract');
		}
		if (fullLocation === replaceSuspendContractUrl(id)) {
			sectionName = t('bookings.single_booking.details.suspend_contract');
		}

		// driver license
		if (fullLocation === URLS.driverLicenseList) {
			sectionName = t('nav.administrative.customers.driver_license_list');
		}

		const promoCodeSectionName = getPromoCodeSectionName(fullLocation);

		if (null != promoCodeSectionName) {
			sectionName = promoCodeSectionName;
		}

		// b2b
		if (fullLocation === URLS.corporateOverviewUrl) {
			sectionName = t('nav.corporate.overview');
		}

		if (fullLocation === replaceEditCorporateUrl(id)) {
			sectionName = t('b2b.corporate.form.edit_corporate');
		}

		if (fullLocation === URLS.addCorporateUrl) {
			sectionName = t('b2b.corporate.form.add_corporate');
		}

		if (fullLocation === replaceEditCorporateCustomerUrl(id)) {
			sectionName = t('b2b.corporate.overview.table.tooltips.edit_customer');
		}

		if (fullLocation === replaceCorporateSettlementsUrl(id)) {
			sectionName = t('b2b.settlements.title');
		}

		if (fullLocation === replaceUnsettledBookingsUrl(id)) {
			sectionName = t('b2b.settlements.unsettled_bookings');
		}

		if (fullLocation === replaceExternalPartnersListUrl(id)) {
			sectionName = t('offices.external_partner_cars');
		}

		// Leasing cars
		if (fullLocation === replaceLeasingCarsUrl(id)) {
			sectionName = `${t(titlesArray[0])} / ${t(
				'offices.table.headers.leasing_cars_list'
			)}`;

			if (fullLocation.includes('create')) {
				sectionName += ` / ${t('common.add')}`;
			}
		}

		if (fullLocation === replaceAddLeasingCarUrl(officeId)) {
			sectionName = `${t(titlesArray[0])} / ${t(
				'offices.table.headers.leasing_cars'
			)} / ${t('common.add')}`;
		}

		if (fullLocation === replaceEditLeasingCarUrl({ officeId, vehicleId })) {
			sectionName = `${t(titlesArray[0])} / ${t(
				'offices.table.headers.leasing_cars'
			)} / ${t('common.edit')}`;
		}

		return sectionName;
	};

	const sectionName = getSectionName();

	return {
		isTabletSize,
		sectionName,
	};
};
