import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CAR_LIST_MANUFACTURERS_QUERY } from 'helpers';

export const useManufacturers = () => {
	const path = `/v2/supplier/vehicle/maker/list`;

	const { data, isError, isFetching } = useQuery(
		CAR_LIST_MANUFACTURERS_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, cacheTime: 0 }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};
