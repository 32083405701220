import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	button: { minWidth: theme.layout.width.button },
	chip: { backgroundColor: theme.palette.white, fontWeight: 500 },

	canceled: { color: theme.palette.text.secondary },
	accepted: { color: theme.palette.success.main },
	pending: { color: theme.palette.error.main },
	changed: { color: theme.palette.text.secondary },
	rejected: { color: theme.palette.error.main },
	deleted: { color: theme.palette.text.secondary },
}));

export default useStyles;
