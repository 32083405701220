import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CUSTOMER_PHONE_PREFIXES_QUERY } from 'helpers';

const usePhonePrefixes = () => {
	const path = '/v2/content/country-phones';

	const { data, isFetching } = useQuery(
		CUSTOMER_PHONE_PREFIXES_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, staleTime: 60 * 60 * 1000 }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching };
};

export default usePhonePrefixes;
