import React from 'react';
import { useSelector } from 'react-redux';

// Import utils
import { useProfile, useTranslations } from 'components/utilities';

// Import components
import PreviewFields from '../PreviewFields';
import { Card } from 'components/elements';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import helpers
import { getCustomData, getEarlyReturnFields } from './helpers';

export const EarlyReturnCard = () => {
	const { t } = useTranslations();

	const booking = useSelector(previewBookingSelector);

	const { isAdmin } = useProfile();

	const {
		subscription: isSubscription,
		early_return_details: earlyReturnDetails,
	} = booking || {};

	if (!earlyReturnDetails || !isAdmin) return null;

	const customData = getCustomData({ isSubscription, earlyReturnDetails });
	const earlyReturnFields = getEarlyReturnFields({
		isSubscription,
		earlyReturnDetails,
	});

	return (
		<Card title={t('bookings.preview_booking.early_return.title')}>
			<PreviewFields fields={earlyReturnFields} customData={customData} />
		</Card>
	);
};
