import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { OFFICES_ASSIGNED_TO_USER_COLLECTION_QUERY } from 'helpers';

const useOfficesAssignedToUser = () => {
	const officesCollectionUrl = `/v2/supplier/business/manager/offices/collection`;

	const {
		data,
		isFetching,
	} = useQuery(
		OFFICES_ASSIGNED_TO_USER_COLLECTION_QUERY,
		async () => await services.get(officesCollectionUrl),
		{ refetchOnWindowFocus: false }
	);
	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching };
};

export default useOfficesAssignedToUser;
