import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { useBookingCounters } from 'queries';

const BookingCountChip = ({ reference }) => {
	const { data: count } = useBookingCounters(reference);
	if (typeof count !== 'number') {
		return null;
	}

	return <Chip color="primary" size="small" label={count} />;
};

BookingCountChip.propTypes = {
	reference: PropTypes.string,
};

export default BookingCountChip;
