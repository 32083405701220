// Import variables
import localStorage from 'services/localStorage';
import i18n from 'i18n';

export const getHeaders = (config) => {
	let user = localStorage.storedLsValue() || null;
	const language = i18n.language;

	let headers = {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		Accept: 'application/json',
		language,
		app: 'dashboard',
		Platform: 'web',
		...config,
	};

	if (user) {
		headers['Authorization'] = `Bearer ${user.data.meta.access_token}`;
	}

	return headers;
};
