import React from 'react';
import { object, array, string, oneOfType } from 'prop-types';
import PreviewFieldFactory from './PreviewFieldFactory';

const PreviewFields = ({ fields, color, customData }) => {
	return (
		<>
			{fields.map((field) => (
				<PreviewFieldFactory
					customData={customData}
					key={field.accessor}
					color={color}
					{...field}
				/>
			))}
		</>
	);
};

PreviewFields.propTypes = {
	customData: oneOfType([array, object]),
	fields: array,
	color: string,
};

export default PreviewFields;
