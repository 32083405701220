import React from 'react';
import { Typography } from '@material-ui/core';
import { object } from 'prop-types';

// Import components
import PreviewField from '../PreviewField';

// Import utilities
import { useDiscountPreviewField } from './useDiscountPreviewField';

export const DiscountPreviewField = ({ customData }) => {
	const discount = customData.discount;

	const { label, fixedDiscount, color } = useDiscountPreviewField(discount);

	return (
		<PreviewField label={label}>
			<Typography color={color}>{fixedDiscount}</Typography>
		</PreviewField>
	);
};

DiscountPreviewField.propTypes = {
	customData: object,
};
