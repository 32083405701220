import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

// Import utils
import { byCustomParameterColumns, byDateColumns } from './columns';

export const exportToExcel = ({ data }) => ({
	async download(filename = 'TELGANI.xls') {
		const workbook = new ExcelJS.Workbook();

		const sheet = workbook.addWorksheet();

		const [byDateTableData, byCustomParameterTableData] = data;

		const formattedByDateData = byDateTableData.map(({ count, date }) => [
			date,
			count,
		]);

		const formattedByPhoneData = byCustomParameterTableData.map(
			({ count, phoneNumber }) => [phoneNumber, count]
		);

		sheet.addTable({
			name: 'byDate',
			ref: 'A1',
			headerRow: true,
			totalsRow: false,
			style: {
				theme: 'TableStyleLight9',
				showRowStripes: true,
			},
			columns: byDateColumns,
			rows: formattedByDateData,
		});

		sheet.addTable({
			name: 'byCustomParameter',
			ref: 'D1',
			headerRow: true,
			totalsRow: false,
			style: {
				theme: 'TableStyleLight9',
				showRowStripes: true,
			},
			columns: byCustomParameterColumns,
			rows: formattedByPhoneData,
		});

		sheet.columns = sheet.columns.map((column) => ({ ...column, width: 25 }));

		const buffer = await workbook.xlsx.writeBuffer();

		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
	},
});
