import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './components/routes';
import { ThemeProvider } from '@material-ui/styles';
import { LastLocationProvider } from 'react-router-last-location';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { useStore } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

// Import styles
import { generateTheme } from './theme';

// Import helpers
import { EN, DIRECTIONS } from 'helpers/variables';

// Import utilities
import { isAuthenticatedSelector } from 'store/selectors';
import { useTranslations, NotificationsProvider } from 'components/utilities';
import { removeSensitiveData } from './helpers';
import { updateUserProfile } from 'store/actions';

const releaseStage = process.env.REACT_APP_RELEASE_STAGE || 'errorStage';

Bugsnag.start({
	apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
	releaseStage,
	plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const Root = () => {
	const { i18n } = useTranslations();

	const { getState } = useStore();

	const directions = i18n.language === EN ? DIRECTIONS.LTR : DIRECTIONS.RTL;
	const isAuthenticated = useSelector(isAuthenticatedSelector);
	const direction = directions.toLowerCase();
	const dispatch = useDispatch();

	useEffect(() => {
		updateUserProfile()(dispatch);
	}, [dispatch, isAuthenticated]);

	const handleOnError = (event) => {
		const state = getState();
		const store = removeSensitiveData(state);

		event.addMetadata('store', {
			store,
		});
	};

	return (
		<ErrorBoundary onError={handleOnError}>
			<NotificationsProvider>
				<ThemeProvider theme={{ ...generateTheme(i18n.language), direction }}>
					<BrowserRouter>
						<LastLocationProvider>
							<Routes />
						</LastLocationProvider>
					</BrowserRouter>
				</ThemeProvider>
			</NotificationsProvider>
		</ErrorBoundary>
	);
};

export default Root;
