import React from 'react';
import { Box } from '@material-ui/core';
import { fetchCarList } from 'store/actions';
import { useLocation } from 'react-router-dom';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './columns';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow } from './components';

// Import utils
import { useProfile, useTranslations } from 'components/utilities';
import { TableActionsProvider } from './context';
import { func } from 'prop-types';

export const CarList = ({ onSelectPlate }) => {
	const { pathname } = useLocation();
	const classes = useBaseLayoutStyles();

	const { i18n } = useTranslations();

	const { isAdmin } = useProfile();

	const lng = i18n.language;

	const isContractPage = pathname.includes('contract');

	return (
		<TableProvider
			columns={columns({ lng, isAdmin, isContractPage })}
			fetchDataTableAction={fetchCarList}
		>
			<TableActionsProvider>
				<Box className={classes.root}>
					<Toolbar content={TOOLBAR_CONTENT} />
					<Box className={classes.content}>
						<Table isDefaultRow={false}>
							{({ row }) => (
								<TableBodyRow
									key={row.id}
									row={row}
									isContractPage={isContractPage}
									onSelectPlate={onSelectPlate}
								/>
							)}
						</Table>
					</Box>
				</Box>
			</TableActionsProvider>
		</TableProvider>
	);
};

CarList.propTypes = {
	onSelectPlate: func,
};

CarList.defaultProps = {
	onSelectPlate: () => {},
};
