import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CANCELLATION_REASONS_QUERY } from 'helpers';

const useCancellationReasons = (enabled = true) => {
	const path = `/v2/booking/cancel-reasons`;

	const { data, isFetched } = useQuery(
		CANCELLATION_REASONS_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetched };
};

export default useCancellationReasons;
