import i18next from 'i18next';

export const byDateColumns = [
	{ name: i18next.t('common.fields.date') },
	{ name: i18next.t('common.fields.total_users') },
];

export const byCustomParameterColumns = [
	{ name: i18next.t('common.fields.custom_parameter') },
	{ name: i18next.t('common.fields.total_users') },
];
