import { useEffect } from 'react';
import { useQuery, queryCache } from 'react-query';
import { useForm } from 'react-final-form';
import services from 'services/services';

// Import helpers
import { createCarYearsBody } from './helpers';
import { CAR_MODELS_QUERY, CAR_YEARS_QUERY } from 'helpers';

const useCarYears = ({
	isGlobalEdit,
	vehicleList,
	manufacturer,
	model_name,
	initialManufacturer,
	initialModel,
	yearFieldName,
	lng,
	modelFieldName,
}) => {
	const { change } = useForm();

	const path = isGlobalEdit
		? '/v2/supplier/vehicle/model/year/list'
		: '/v2/supplier/vehicle/year/list';

	const getFieldObj = isGlobalEdit ? model_name : manufacturer;
	const fieldName = getFieldObj[lng];

	const initialValue = isGlobalEdit ? initialModel : initialManufacturer;

	const modelsData = queryCache.getQueryData(CAR_MODELS_QUERY);
	const models = modelsData && modelsData.data.data;

	useEffect(() => {
		if (initialValue !== getFieldObj) {
			!isGlobalEdit && change(modelFieldName, null);
			change(yearFieldName, null);
		}
		refetch(getFieldObj);

		//eslint-disable-next-line
	}, [getFieldObj, vehicleList.length]);

	const body = createCarYearsBody({
		fieldName,
		models,
		model_name,
		isGlobalEdit,
		vehicleList,
		manufacturer,
		lng,
	});

	const { data, isFetching, isError, refetch } = useQuery(
		CAR_YEARS_QUERY,
		async () =>
			fieldName &&
			vehicleList.length !== 0 &&
			(await services.post(path, body)),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};

export default useCarYears;
