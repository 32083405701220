import React from 'react';
import { func } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import helpers
import { URLS } from 'components/routes';

// Import selectors
import { isAuthenticatedSelector, isForcePasswordReset } from 'store/selectors';

const ProtectedDashboard = ({ component: Component }) => {
	const isAuthenticated = useSelector(isAuthenticatedSelector);
	const forcePasswordReset = useSelector(isForcePasswordReset);

	return (
		<Route
			render={(props) =>
				isAuthenticated ? (
					forcePasswordReset ? (
						<Redirect to={URLS.resetPasswordUrl} />
					) : (
						<Component {...props} />
					)
				) : (
					<Redirect to={URLS.signInUrl} />
				)
			}
		/>
	);
};

ProtectedDashboard.propTypes = {
	component: func.isRequired,
};

export default ProtectedDashboard;
