import { t } from 'i18next';
import { format } from 'date-fns';

// Import helpers
import {
	DEFAULT_DATE_FORMAT,
	EARLY_RETURN_TRANSACTIONAL_TYPES,
	EMPTY_VALUE,
} from 'helpers';

const { PAYMENT, REFUND } = EARLY_RETURN_TRANSACTIONAL_TYPES;

export const getAmountLabel = ({ transactionalType, isSubscription }) => {
	const paymentLabel =
		'bookings.preview_booking.early_return.fee_to_be_paid_by_customer';
	const refundLabel =
		'bookings.preview_booking.early_return.refund_to_customer';

	switch (transactionalType) {
		case PAYMENT:
			return paymentLabel;
		case REFUND:
			return refundLabel;
		default:
			return isSubscription ? paymentLabel : refundLabel;
	}
};

export const getEarlyReturnFields = ({
	earlyReturnDetails,
	isSubscription,
}) => {
	const { transactional_type: transactionalType } = earlyReturnDetails || {};

	const fields = [
		{
			accessor: 'request_date',
			label: 'bookings.preview_booking.early_return.request_date',
		},
		{
			accessor: 'return_date',
			label: 'bookings.preview_booking.early_return.return_date',
		},
		{
			accessor: 'paid_at',
			label: 'common.fields.paid_at',
		},
		{
			accessor: 'view_attachment',
			label: 'bookings.preview_booking.early_return.contract',
		},
		{
			accessor: 'previous_booking_amount',
			label: 'bookings.preview_booking.early_return.previous_booking_amount',
		},
		{
			accessor: 'new_booking_amount',
			label: 'bookings.preview_booking.early_return.new_booking_amount',
		},
		{
			accessor: 'transaction_amount',
			label: getAmountLabel({ transactionalType, isSubscription }),
		},
	];

	return fields;
};

export const getCustomData = ({ earlyReturnDetails }) => {
	const {
		early_return_transaction: earlyReturnTransaction,
		new_subscription_period: newSubscriptionPeriod,
		old_subscription_period: oldSubscriptionPeriod,
		request_date: requestDate,
		return_date: returnDate,
		old_price: oldPrice,
		new_price: newPrice,
		paid_at: paidAt,
		attachments,
	} = earlyReturnDetails || {};

	const convertedAttachments = Object.entries(attachments).reduce(
		(acc, [name, file]) => {
			const convertedName = name.slice(0, -4);
			if (file)
				return [...acc, { label: t(`common.fields.${convertedName}`), file }];
			return acc;
		},
		[]
	);

	const getMonthLabel = (monthsNumber) => {
		if (monthsNumber === 1)
			return t('bookings.preview_booking.early_return.one_month_rate');
		else if (monthsNumber > 1)
			return t('bookings.preview_booking.early_return.months_rate', {
				monthsNumber,
			});
		else return '';
	};

	const newPriceAmount = newPrice.amount_gross;
	const oldPriceAmount = oldPrice.amount_gross;
	const transactionAmount = earlyReturnTransaction.amount_gross;
	const newSubscriptionPeriodLabel = getMonthLabel(newSubscriptionPeriod);
	const oldSubscriptionPeriodLabel = getMonthLabel(oldSubscriptionPeriod);

	const newPriceTrans = `${t('currency.price', {
		amount: newPriceAmount,
	})} ${newSubscriptionPeriodLabel}`;
	const oldPriceTrans = `${t('currency.price', {
		amount: oldPriceAmount,
	})} ${oldSubscriptionPeriodLabel}`;
	const transactionPriceTrans = t('currency.price', {
		amount: transactionAmount,
	});

	return {
		request_date: format(new Date(requestDate), DEFAULT_DATE_FORMAT),
		return_date: format(new Date(returnDate), DEFAULT_DATE_FORMAT),
		paid_at: format(new Date(paidAt), DEFAULT_DATE_FORMAT),
		previous_booking_amount: oldPriceAmount ? oldPriceTrans : EMPTY_VALUE,
		new_booking_amount: newPriceAmount ? newPriceTrans : EMPTY_VALUE,
		transaction_amount: transactionAmount ? transactionPriceTrans : EMPTY_VALUE,
		attachments: convertedAttachments,
	};
};
