import { format } from 'date-fns';

// Import utils
import myAxios from 'services/myAxiosFactory';
import services from 'services/services';

// Import helpers
import { BOOKING_STATES, PLATE_RENT_STATUS, YMD_DATE_FORMAT } from 'helpers';
import { convertSaveContractValues } from './helpers';
import localStorage from 'services/localStorage';
import {
	sendContractOtpUrl,
	createContractUrl,
	updateBookingUrl,
	saveContractUrl,
	updatePlateUrl,
	updateBookingStateUrl,
	saveContractAsDraftUrl,
} from 'store/paths';

export const saveContract = async ({
	callbackSuccess,
	errorCallback,
	bookingUuid,
	values,
	isDraft = false,
}) => {
	try {
		const convertedValues = convertSaveContractValues({
			values,
			bookingUuid,
			isDraft,
		});

		const savedData = await services.post(
			isDraft ? saveContractAsDraftUrl : saveContractUrl,
			convertedValues
		);

		let localStorageData = {};
		if (!isDraft) {
			const { contractNumber } = savedData?.data?.data || {};
			await services.put(
				updatePlateUrl(convertedValues.vehicle_details.vehicle_plate_uuid),
				{
					rent_status: PLATE_RENT_STATUS.RENTED,
					miles_usage: convertedValues.rent_status.odometer_reading,
					booking_uuid: bookingUuid,
				}
			);

			localStorageData = {
				workingBranchId:
					convertedValues.authorization_details.working_branch_id,
				plate_uuid: convertedValues.vehicle_details.vehicle_plate_uuid,
				operator_id: convertedValues.authorization_details.operator_id,
				contractNumber,
				bookingUuid,
			};
		}

		callbackSuccess(localStorageData);
	} catch (error) {
		if (error.response) {
			const { errors, message } = error.response.data || {};
			errorCallback(errors || message);
		}
	}
};

export const sendContractOTP = async ({
	successCallback,
	errorCallback,
	contractNumber,
}) => {
	try {
		await services.post(sendContractOtpUrl, {
			contract_number: contractNumber,
		});
		successCallback();
	} catch (error) {
		if (error.response) {
			const { errors, message } = error.response.data || {};
			const callbackText = errors ? errors.contract_number[0] : message;
			errorCallback(callbackText);
		}
	}
};

export const createContract = async ({
	callbackSuccess,
	errorCallback,
	bookingUuid,
	body,
}) => {
	try {
		let formData = new FormData();

		const signature = new File([body.signature], 'image.jpeg', {
			type: 'image/jpeg',
		});

		formData.append('signature', signature);
		formData.append('working_branch_id', body.working_branch_id);
		formData.append('contract_number', body.contract_number);
		formData.append('operator_id', body.operator_id);

		if (body.otp_value) {
			formData.append('otp_value', body.otp_value);
		}

		await myAxios().post(createContractUrl, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		const { plate_uuid } = localStorage.storedLsValue('contract');

		await services.put(updatePlateUrl(plate_uuid), {
			rent_status: PLATE_RENT_STATUS.RENTED,
			last_open_contract_date: format(new Date(), YMD_DATE_FORMAT),
			booking_uuid: bookingUuid,
		});

		await services.put(updateBookingUrl(bookingUuid), {
			contract_number: body.contract_number,
		});

		await services.put(updateBookingStateUrl(bookingUuid), {
			state: BOOKING_STATES.CONTRACT_OPENED,
		});

		callbackSuccess();
	} catch (error) {
		if (error.response) {
			const apiErrors = error.response.data.errors;
			errorCallback(apiErrors);
		}
	}
};
