import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { RATING_REASONS_QUERY } from 'helpers';

const useRatingReasons = ({ enabled = true, rating_type = '' }) => {
	const path = '/v2/booking/rating-reasons';

	const { data, isFetching } = useQuery(
		`${RATING_REASONS_QUERY}_${rating_type}`,
		async () => await services.post(path, { rating_type }),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};

export default useRatingReasons;
