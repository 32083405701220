import services from 'services/services';
import * as types from 'store/types';
import { businessDashboardUrl, businessManagerDashboardUrl } from 'store/paths';

// Import helpers
import { convertPeriod } from './helpers';

export const fetchDashboardInfo =
	({ values }) =>
	async (dispatch) => {
		dispatch({
			type: types.DASHBOARD_INFO_FETCH_DATA_LOADING,
		});

		const newValues = {
			...values,
			period: convertPeriod(values.period),
		};

		try {
			const dashboardDataPromise = services.post(
				businessDashboardUrl,
				newValues
			);

			const managerDataPromise = services.post(
				businessManagerDashboardUrl,
				newValues
			);

			const values = await Promise.all([
				dashboardDataPromise,
				managerDataPromise,
			]);

			const [{ data: dashboardData }, { data: managerData }] = values;

			dispatch({
				type: types.DASHBOARD_INFO_FETCH_DATA_SUCCESS,
				payload: {
					...dashboardData.data,
					...managerData.data,
				},
			});
		} catch (error) {
			dispatch({
				type: types.DASHBOARD_INFO_FETCH_DATA_ERROR,
				payload: error,
			});
		}
	};
