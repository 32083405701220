// Import helpers
import { SERVICES_TYPES } from 'helpers';

const { AIRPORT, DELIVERY, PICKUP, SUBSCRIPTION } = SERVICES_TYPES;

export const OPTIONS = [
	{
		value: AIRPORT,
		label: 'common.filters.airport',
	},
	{
		value: DELIVERY,
		label: 'common.filters.delivery',
	},
	{
		value: PICKUP,
		label: 'common.filters.pickup',
	},
	{
		value: SUBSCRIPTION,
		label: 'common.filters.subscription',
	},
];
