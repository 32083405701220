import React from 'react';

// Import helpers
import { BOOKING_CHIP_REFERENCES } from 'helpers';

// Import components
import BookingCountChip from '../BookingCountChip';

export const ExtendedBookingsCountChip = () => {
	return <BookingCountChip reference={BOOKING_CHIP_REFERENCES.EXTENDED} />;
};
