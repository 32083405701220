// Import helpers
import { URLS } from 'components/routes';

export const replaceUrl = (url, params) => {
	let modifiedUrl = url;

	Object.entries(params).forEach(([key, value]) => {
		modifiedUrl = modifiedUrl.replace(key, value);
	});

	return modifiedUrl;
};

export const replaceOfficeDetailsUrl = (id) =>
	replaceUrl(URLS.officeDetailsUrl, { ':id': id });

export const replacePreviewBookingUrl = (id) =>
	replaceUrl(URLS.previewBookingUrl, { ':id': id });

export const replacePrintBookingUrl = (id) =>
	replaceUrl(URLS.printBookingUrl, { ':id': id });

export const replaceAirportTerminalsUrl = (id) =>
	replaceUrl(URLS.airportTerminalsUrl, { ':id': id });

export const replaceEditKioskAirportUrl = (id) =>
	replaceUrl(URLS.editKioskAirportUrl, { ':id': id });

export const replaceEditAirportTerminalUrl = (airportId, id) =>
	replaceUrl(URLS.editAirportTerminalUrl, {
		':airportId': airportId,
		':id': id,
	});

export const replaceEditBookingUrl = (id) =>
	replaceUrl(URLS.editBookingUrl, { ':id': id });

export const replaceCustomerPreviewUrl = (id) =>
	replaceUrl(URLS.customerPreviewUrl, { ':id': id });

export const replaceAddCampaignsUrl = (type) =>
	replaceUrl(URLS.addCampaignsUrl, { ':type': type });

export const replaceEditCampaignsUrl = (id) =>
	replaceUrl(URLS.editCampaignsUrl, { ':id': id });

export const replaceEditCancellationReasonUrl = (id) =>
	replaceUrl(URLS.editCancellationReasonUrl, { ':id': id });

export const replaceCarsUrl = (id) => replaceUrl(URLS.carsUrl, { ':id': id });
export const replaceLeasingCarsUrl = (id) =>
	replaceUrl(URLS.leasingCarsUrl, { ':id': id });

export const replaceEditCarListUrl = (id) =>
	replaceUrl(URLS.editCarListUrl, { ':id': id });

export const replaceEditCarManufacturerUrl = (id) =>
	replaceUrl(URLS.editCarManufacturerUrl, { ':id': id });

export const replaceEditCarModelUrl = (id) =>
	replaceUrl(URLS.editCarModelUrl, { ':id': id });

export const replaceEditCompanyUrl = (id) =>
	replaceUrl(URLS.editCompanyUrl, { ':id': id });

export const replaceCompanyDropCitiesUrl = (id) =>
	replaceUrl(URLS.companyDropCitiesUrl, { ':id': id });

export const replaceCompanyUsersUrl = (id) =>
	replaceUrl(URLS.companyUsersUrl, { ':id': id });

export const replaceCompanyOfficesUrl = (id) =>
	replaceUrl(URLS.companyOfficesUrl, { ':id': id });

export const replaceEditCompanyManagersUrl = (id) =>
	replaceUrl(URLS.editCompanyManagersUrl, { ':id': id });

export const replaceEditCustomerUrl = (id) =>
	replaceUrl(URLS.editCustomerUrl, { ':id': id });

export const replaceOrganizationDiscountsUrl = (id) =>
	replaceUrl(URLS.organizationDiscountsUrl, { ':id': id });

export const replaceEditOrganizationDiscountUrl = (organizationId, id) =>
	replaceUrl(URLS.editOrganizationDiscountUrl, {
		':organizationId': organizationId,
		':id': id,
	});

export const replaceEditKioskUrl = (id) =>
	replaceUrl(URLS.editKioskUrl, { ':id': id });

export const replaceEditOfficeUrl = (id) =>
	replaceUrl(URLS.editOfficeUrl, { ':id': id });

export const replaceEditPromoCodeUrl = (id) =>
	replaceUrl(URLS.editPromoCodeUrl, { ':id': id });

export const replacePromoCodeUrl = (id) =>
	replaceUrl(URLS.promoCodeUrl, { ':id': id });

export const replaceEditRatingReasonsUrl = (id) =>
	replaceUrl(URLS.editRatingReasonsUrl, { ':id': id });

export const replaceEditSliderUrl = (id) =>
	replaceUrl(URLS.editSliderUrl, { ':id': id });

export const replaceEditSpecialOfferUrl = (id, type) =>
	replaceUrl(URLS.editSpecialOfferUrl, { ':id': id, ':type': type });

export const replaceAddSpecialOfferUrl = (serviceType) =>
	replaceUrl(URLS.addSpecialOfferUrl, { ':type': serviceType });

export const replaceEditUrgentReasonsUrl = (id) =>
	replaceUrl(URLS.editUrgentReasonsUrl, { ':id': id });

export const replaceEditUserUrl = (id) =>
	replaceUrl(URLS.editUserUrl, { ':id': id });

export const replaceCompanyEmployeePerformanceUrl = (id) =>
	replaceUrl(URLS.employeeRewardingSettingsUrl, { ':id': id });

export const replaceEmployeePerformanceBookingsWithIdUrl = ({
	id,
	companyUuid,
}) =>
	replaceUrl(URLS.employeeRewardingBookingsWithIdUrl, {
		':id': id,
		':companyUuid': companyUuid,
	});

export const replaceEmployeePerformanceUsersWithIdUrl = (id) =>
	replaceUrl(URLS.employeeRewardingUsersWithIdUrl, { ':id': id });

export const replaceOfficeExternalPartnerCarsUrl = (id) =>
	replaceUrl(URLS.officeExternalPartnerCars, { ':id': id });

export const replaceExternalPartnersListUrl = (id) =>
	replaceUrl(URLS.externalPartnersList, { ':id': id });

export const replaceAddCarUrl = (id) =>
	replaceUrl(URLS.addCarUrl, { ':id': id });

export const replaceEditDriverUrl = (id) =>
	replaceUrl(URLS.editDriverUrl, { ':id': id });

export const replaceEditCorporateUrl = (id) =>
	replaceUrl(URLS.editCorporateUrl, { ':id': id });

export const replaceCreateContractUrl = (id) =>
	replaceUrl(URLS.createContractUrl, { ':id': id });

export const replaceViewDraftUrl = (id) =>
	replaceUrl(URLS.ViewContractDraftUrl, { ':id': id });

export const replaceContractUrl = (id) =>
	replaceUrl(URLS.contractUrl, { ':id': id });

export const replaceCloseContractUrl = (id) =>
	replaceUrl(URLS.closeContractUrl, { ':id': id });

export const replaceExtendContractUrl = (id) =>
	replaceUrl(URLS.extendContractUrl, { ':id': id });

export const replaceSuspendContractUrl = (id) =>
	replaceUrl(URLS.suspendContractUrl, { ':id': id });

export const replaceEditCmsPartnersUrl = (id) =>
	replaceUrl(URLS.editCmsPartnersUrl, { ':id': id });

export const replaceEditPlateUrl = (id) =>
	replaceUrl(URLS.editPlateUrl, { ':id': id });

export const replaceEditFaqUrl = (id) =>
	replaceUrl(URLS.editFaqUrl, { ':id': id });

export const replaceCorporateBookingsUrl = (id) =>
	replaceUrl(URLS.corporateBookingsUrl, { ':id': id });

export const replaceEditCorporateCustomerUrl = (id) =>
	replaceUrl(URLS.editCorporateCustomerUrl, { ':id': id });

export const replaceCorporateSettlementsUrl = (id) =>
	replaceUrl(URLS.corporateSettlementsUrl, { ':id': id });

export const replaceUnsettledBookingsUrl = (id) =>
	replaceUrl(URLS.unsettledBookingsUrl, { ':id': id });

export const replaceAddLeasingCarUrl = (id) =>
	replaceUrl(URLS.addLeasingCarUrl, { ':officeId': id });

export const replaceEditLeasingCarUrl = ({ vehicleId, officeId }) =>
	replaceUrl(URLS.editLeasingCarUrl, {
		':vehicleId': vehicleId,
		':officeId': officeId,
	});

export const replaceEditTrainStationUrl = ({ uuid }) =>
	replaceUrl(URLS.editTrainStation, { ':uuid': uuid });
