import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import {
	replaceEditBookingUrl,
	replaceOfficeDetailsUrl,
	replacePreviewBookingUrl,
} from 'helpers';

// Import components
import {
	PickDateTableCell,
	NoteTableCell,
	ActionsTableCell,
	LinkTableCell,
	RatingReasonTableCell,
	EmployeeTableCell,
	BookingTopicsTableCell,
	UrgentStatusTableCell,
	CompensationAmountTableCell,
} from 'components/elements';

const {
	TOPIC,
	NOTE,
	BOOKING_ID,
	CUSTOMER_NAME,
	OFFICE_NAME,
	PICK_DATE,
	RATING_REASON,
	EMPLOYEE,
	ACTIONS,
	URGENT_STATUS,
	COMPENSATION_AMOUNT,
} = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case NOTE:
				return <NoteTableCell key={cell.column.id} cell={cell} />;

			case COMPENSATION_AMOUNT:
				return <CompensationAmountTableCell key={cell.column.id} cell={cell} />;

			case ACTIONS:
				return (
					<ActionsTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditBookingUrl(cell.row.original.uuid)}
						canEdit={cell.row.original.can_edit}
					/>
				);

			case OFFICE_NAME:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceOfficeDetailsUrl(cell.row.original.office_uuid)}
					>
						{cell.row.original.office_name}
					</LinkTableCell>
				);

			case BOOKING_ID:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePreviewBookingUrl(cell.row.original.uuid)}
					>
						{cell.row.original.individual_number}
					</LinkTableCell>
				);

			case PICK_DATE:
				return <PickDateTableCell cell={cell} key={cell.column.id} />;

			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.customer_name}
						<br /> {cell.row.original.customer_phone}
					</TableCell>
				);

			case TOPIC:
				return <BookingTopicsTableCell key={cell.column.id} cell={cell} />;

			case RATING_REASON:
				return <RatingReasonTableCell key={cell.column.id} cell={cell} />;

			case EMPLOYEE:
				return <EmployeeTableCell key={cell.column.id} cell={cell} />;

			case URGENT_STATUS:
				return cell.row.original.urgentAssistance ? (
					<UrgentStatusTableCell key={cell.column.id} cell={cell} />
				) : (
					<TableCell key={cell.column.id} {...cell.getCellProps()} />
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
