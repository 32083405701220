import React from 'react';

// Import helpers
import { BOOKING_CHIP_REFERENCES } from 'helpers';

// Import components
import BookingCountChip from '../BookingCountChip';

const BookingAccountingCountChip = () => {
	return <BookingCountChip reference={BOOKING_CHIP_REFERENCES.ACCOUNTING} />;
};

export default BookingAccountingCountChip;
