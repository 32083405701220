export const CARS_LOG_HISTORY_QUERY = 'CARS_LOG_HISTORY_QUERY';
export const CAR_YEARS_QUERY = 'CAR_YEARS_QUERY';
export const MODEL_CAR_YEARS_QUERY = 'MODEL_CAR_YEARS_QUERY';
export const CAR_MODELS_QUERY = 'CAR_MODELS_QUERY';
export const CAR_SEARCH_CAR_TYPES_QUERY = 'CAR_SEARCH_CAR_TYPES_QUERY ';
export const CAR_SEARCH_CAR_MODELS_QUERY = 'CAR_SEARCH_CAR_MODELS_QUERY';
export const CAR_SEARCH_CARS_QUERY = 'CAR_SEARCH_CARS_QUERY';
export const ALL_CAR_MODELS_QUERY = 'ALL_CAR_MODELS_QUERY';
export const OFFICES_CARS_QUERY = 'OFFICES_CARS_QUER';
export const OFFICE_LEASING_CARS_EXPORT_QUERY =
	'OFFICES_LEASING_CARS_EXPORT_QUERY';
export const OFFICE_LEASING_CARS_IMPORT_QUERY =
	'OFFICES_LEASING_CARS_IMPORT_QUERY';

export const DRIVER_LICENSE_IMAGE_QUERY = 'DRIVER_LICENSE_IMAGE_QUERY';

export const USER_ASSIGN_OFFICES_QUERY = 'USER_ASSIGN_OFFICES_QUERY';

export const COMPANY_COLLECTION_QUERY = 'COMPANY_COLLECTION_QUERY';
export const COMPANY_COLLECTION_CAR_FILTER_QUERY =
	'COMPANY_COLLECTION_CAR_FILTER_QUERY';
export const COMPANY_COLLECTION_ACTIVE_QUERY =
	'COMPANY_COLLECTION_ACTIVE_QUERY';
export const ACTIVE_COMPANY_COLLECTION_QUERY =
	'ACTIVE_COMPANY_COLLECTION_QUERY';
export const COMPANY_DETAILS_QUERY = 'COMPANY_DETAILS_QUERY';

export const CAR_LIST_MANUFACTURERS_QUERY = 'CAR_LIST_MANUFACTURERS_QUERY';
export const CAR_LIST_VEHICLE_TYPES_QUERY = 'CAR_LIST_VEHICLE_TYPES_QUERY';

export const AUTO_VEHICLE_MAKER_COLLECTION_QUERY =
	'AUTO_VEHICLE_MAKER_COLLECTION_QUERY';
export const AUTO_VEHICLE_MODEL_COLLECTION_QUERY =
	'AUTO_VEHICLE_MODEL_COLLECTION_QUERY';

export const CANCELLATION_REASONS_QUERY = 'CANCELLATION_REASONS_QUERY';

export const URGENT_REASONS_QUERY = 'URGENT_REASONS_QUERY';
export const EARLY_RETURN_CALCULATIONS_QUERY =
	'EARLY_RETURN_CALCULATIONS_QUERY';
export const RATING_REASONS_QUERY = 'RATING_REASONS_QUERY';
export const RATING_TYPES_QUERY = 'RATING_TYPES_QUERY';
export const SUBSCRIPTION_PAYMENTS_QUERY = 'SUBSCRIPTION_PAYMENTS_QUERY';
export const PAGE_SLUG_QUERY = 'PAGE_SLUG_QUERY';

export const KIOSK_AIRPORTS_LIST_QUERY = 'KIOSK_AIRPORTS_LIST_QUERY';

export const OFFICES_ASSIGNED_TO_USER_COLLECTION_QUERY =
	'OFFICES_ASSIGNED_TO_USER_COLLECTION_QUERY';

export const COMPANY_OFFICES_QUERY = 'COMPANY_OFFICES_QUERY';

export const CAMPAIGNS_GRATIFICATION_TYPES_QUERY =
	'CAMPAIGNS_GRATIFICATION_TYPES_QUERY';

export const CAMPAIGN_CONDITIONS_QUERY = 'CAMPAIGN_CONDITIONS_QUERY';

export const CAMPAIGN_BOOKINGS_QUERY = 'CAMPAIGN_BOOKINGS_QUERY';

export const OFFICE_COLLECTION_QUERY = 'OFFICE_COLLECTION_QUERY';

export const URGENT_BOOKINGS_STATS_QUERY = 'URGENT_BOOKINGS_STATS_QUERY';

export const ADMINS_QUERY = 'ADMINS_QUERY';

export const SUBSCRIPTION_PRICES_QUERY = 'SUBSCRIPTION_PRICES_QUERY';

export const CITY_COLLECTION_QUERY = 'CITY_COLLECTION_QUERY';
export const ALL_CITIES_QUERY = 'ALL_CITIES_QUERY';

export const PLATE_FORM_OPTIONS_QUERY = 'PLATE_FORM_OPTIONS_QUERY';

export const CARS_AVAILABILITY_EXPORT_COLUMNS_QUERY =
	'CARS_AVAILABILITY_EXPORT_COLUMNS_QUERY';

export const SUPPLIER_VEHICLE_LIST_QUERY = 'SUPPLIER_VEHICLE_LIST_QUERY';

export const DRIVER_USER_LIST_QUERY = 'DRIVER_USER_LIST_QUERY';

export const CORPORATE_STATS_QUERY = 'CORPORATE_STATS_QUERY';

export const DELIVERY_LOCATION_QUERY = 'DELIVERY_LOCATION_QUERY';

// CONTRACT
export const CREATE_CONTRACT_FORM_OPTIONS_QUERY =
	'CREATE_CONTRACT_FORM_OPTIONS_QUERY';
export const CONTRACT_FORM_PRIVACY_POLICY_QUERY =
	'CONTRACT_FORM_PRIVACY_POLICY_QUERY';
export const CONTRACT_PRIVACY_POLICY_QUERY = 'CONTRACT_PRIVACY_POLICY_QUERY';
export const EXTENDED_COVERAGES_QUERY = 'EXTENDED_COVERAGES_QUERY';
export const CONTRACT_BRANCHES_QUERY = 'CONTRACT_BRANCHES_QUERY';
export const INITIATE_CONTRACT_INITIAL_VALUES_QUERY =
	'INITIATE_CONTRACT_INITIAL_VALUES_QUERY';
export const CONTRACT_DATA_QUERY = 'CONTRACT_DATA_QUERY';
export const CONTRACT_PDF_QUERY = 'CONTRACT_PDF_QUERY';
export const VEHICLE_PLATES_QUERY = 'VEHICLE_PLATES_QUERY';
export const SUSPEND_CONTRACT_COLLECTION_QUERY =
	'SUSPEND_CONTRACT_COLLECTION_QUERY';
export const GET_CONTRACT_DRAFT_DATA_QUERY = 'GET_CONTRACT_DRAFT_DATA_QUERY';

// CUSTOMER
export const CUSTOMER_PHONE_PREFIXES_QUERY = 'CUSTOMER_PHONE_PREFIXES_QUERY';
export const CUSTOMER_GENDERS_QUERY = 'CUSTOMER_GENDERS_QUERY';
export const CUSTOMER_TYPE_QUERY = 'CUSTOMER_TYPE_QUERY';
export const CUSTOMER_ORGANIZATIONS_QUERY = 'CUSTOMER_ORGANIZATIONS_QUERY';
export const CUSTOMER_DRIVER_LICENSE_QUERY = 'CUSTOMER_DRIVER_LICENSE_QUERY';
export const CUSTOMER_WALLET_BALANCE = 'CUSTOMER_WALLET_BALANCE';
export const CUSTOMER_WALLET_REASONS = 'CUSTOMER_WALLET_REASONS';
export const CUSTOMER_CANCELLATION_REASONS_QUERY =
	'CUSTOMER_CANCELLATION_REASONS_QUERY';
export const CUSTOMER_WALLET_BALANCE_HISTORY =
	'CUSTOMER_WALLET_BALANCE_HISTORY';

// BOOKING
export const BOOKING_STATES_QUERY = 'BOOKING_STATES_QUERY';
export const BOOKING_COUNT_QUERY = 'BOOKING_COUNT_QUERY';
export const BOOKING_INVOICE_QUERY = 'BOOKING_INVOICE_QUERY';
export const BOOKING_LIST_QUERY = 'BOOKING_LIST_QUERY';
export const EDIT_BOOKING_HISTORY_QUERY = 'EDIT_BOOKING_HISTORY_QUERY';
export const DELIVERY_BOOKING_IMAGES_QUERY = 'DELIVERY_BOOKING_IMAGES_QUERY';

// PROMO CODES
export const PROMO_CODES_LIST = 'PROMO_CODES_LIST';
export const PROMO_CODE_STATISTICS = 'PROMO_CODE_STATISTICS';

// B2B
export const B2B_RENTAL_COMPANIES = 'B2B_RENTAL_COMPANIES';
export const B2B_CORPORATION = 'B2B_CORPORATION';
export const B2B_COORDINATOR = 'B2B_COORDINATOR';
export const B2B_CORPORATION_CUSTOMER = 'B2B_CORPORATION_CUSTOMER';
export const B2B_CORPORATION_CUSTOMERS_LIST = 'B2B_CORPORATION_CUSTOMERS_LIST';
export const B2B_EXTEND_BOOKING_PRICE_CALCULATION =
	'B2B_EXTEND_BOOKING_PRICE_CALCULATION';

export const EXTERNAL_PARTNER_CARS_QUERY = 'EXTERNAL_PARTNER_CARS_QUERY';

// APP VERSION
export const APP_VERSION_QUERY = 'APP_VERSION_QUERY';

// KPI Reports
export const REPORT_DELIVERIES_SUCCESS_RATE_QUERY =
	'REPORT_DELIVERIES_SUCCESS_RATE_QUERY';
export const REPORT_ACCEPTANCE_RATE_QUERY = 'REPORT_ACCEPTANCE_RATE_QUERY';
export const REPORT_BOOKINGS_BY_SERVICE_QUERY =
	'REPORT_BOOKINGS_BY_SERVICE_QUERY';
export const REPORT_BOOKINGS_BY_SOURCE_QUERY =
	'REPORT_BOOKINGS_BY_SOURCE_QUERY';
export const REPORT_BOOKINGS_BY_STATE_QUERY = 'REPORT_BOOKINGS_BY_STATE_QUERY';
export const REPORT_CANCELLATION_RATE_QUERY = 'REPORT_CANCELLATION_RATE_QUERY';
export const REPORT_RATING_QUERY = 'REPORT_RATING_QUERY';
export const REPORT_RESPONSE_TIME_QUERY = 'REPORT_RESPONSE_TIME_QUERY';
export const REPORT_REVENUE_QUERY = 'REPORT_REVENUE_QUERY';
export const REPORT_CANCELLED_TO_ACCEPTED_RATE_QUERY =
	'CANCELLED_TO_ACCEPTED_RATE_QUERY';

// Subscription
export const SUBSCRIPTION_EXTEND_CALCULATION_QUERY =
	'SUBSCRIPTION_EXTEND_CALCULATION_QUERY';

// Train station
export const TRAIN_STATION_COLLECTION_QUERY = 'TRAIN_STATION_COLLECTION_QUERY';
export const TRAIN_STATION_QUERY = 'TRAIN_STATION_QUERY';
