import { useState } from 'react';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';
import { sendContractOTP } from 'store/actions/contract';
import { useContractData } from 'queries';

// Import helpers
import { LOCAL_STORAGE_CONTRACT } from 'helpers';
import localStorage from 'services/localStorage';

export const useSubmitFormContent = () => {
	const [isOtpLoading, setIsOtpLoading] = useState(false);

	const { contractNumber } =
		localStorage.storedLsValue(LOCAL_STORAGE_CONTRACT) || {};

	const { t } = useTranslations();

	const { message } = useMessage();

	const { data: contractData, isFetching: contractDataFetching } =
		useContractData(contractNumber);

	const { is_otp_required: isOtpRequired } = contractData || {};

	const resendOTP = async () => {
		setIsOtpLoading(true);

		const sendContractOTPOptions = {
			contractNumber,
			successCallback: () => {
				message.success(
					t('contract.create_contract_form.success_send_otp_message')
				);
				setIsOtpLoading(false);
			},
			errorCallback: (error) => {
				message.error(error);
				setIsOtpLoading(false);
			},
		};

		await sendContractOTP(sendContractOTPOptions);
	};

	return {
		contractDataFetching,
		isOtpRequired,
		isOtpLoading,
		resendOTP,
	};
};
