import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { COMPANY_COLLECTION_QUERY } from 'helpers';

export const useCompanies = (isActive) => {
	const companyCollectionUrl = `/v2/supplier/business/company/collection${
		isActive ? '-active' : ''
	}`;

	const { data, isFetching, isError } = useQuery(
		COMPANY_COLLECTION_QUERY,
		async () => await services.get(companyCollectionUrl),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};
