import { combineQueryAndPaginationParams } from 'helpers';
import services from 'services/services';

// Import helpers
import { fetchRevenueReportUrl } from 'store/paths';

export const fetchRevenueReport =
	({ queryParams, cancelToken, options }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			if (pageSize <= 1)
				return Promise.resolve({ data: { input: { columns: [] } } });

			const { data } = await services.get(
				fetchRevenueReportUrl(
					combineQueryAndPaginationParams({ queryParams, body })
				),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
