import services from 'services/services';
import * as types from 'store/types';
import {
	businessCompanyActiveCollectionUrl,
	showSpecialOfferUrl,
	submitSpecialOfferFormUrl,
} from 'store/paths';

// import helpers
import { catchError } from '../helpers';
import { convertData } from './helpers';

export const fetchSpecialOfferFormResources =
	({ itemId, cancelToken, isEdit }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.SPECIAL_OFFER_FORM_FETCH_DATA_LOADING,
				payload: true,
			});

			const companyListPromise = services.get(
				businessCompanyActiveCollectionUrl,
				cancelToken
			);

			const allPromises = [companyListPromise];

			if (isEdit) {
				const specialOfferPromise = services.get(
					showSpecialOfferUrl(itemId),
					cancelToken
				);

				allPromises.push(specialOfferPromise);
			}

			const values = await Promise.all(allPromises);

			const [{ data: companyList }] = values;

			const specialOfferData = values[1]?.data || {};

			dispatch({
				type: types.SPECIAL_OFFER_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: specialOfferData,
					companyList: companyList || [],
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.SPECIAL_OFFER_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitSpecialOfferForm =
	({ values, itemId, isEdit, serviceType, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			const convertedData = convertData(values, serviceType);

			await services.post(
				submitSpecialOfferFormUrl(itemId, isEdit),
				convertedData
			);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
