import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { BOOKING_COUNT_QUERY } from 'helpers';

const useBookingCounters = (reference) => {
	const path = `v2/booking/${reference}/counts`;

	const { data, isFetching } = useQuery(
		[BOOKING_COUNT_QUERY, reference],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, staleTime: 60 * 1000 }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching };
};

export default useBookingCounters;
