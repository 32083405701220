import * as types from 'store/types';
import services from 'services/services';
import { fetchDefaultSeoTypesUrl } from 'store/paths';

//Import helpers
import { catchError } from '../helpers';

export const fetchDefaultSeoTypes = () => async (dispatch) => {
	try {
		dispatch({
			type: types.DEFAULT_SEO_TYPES_LOADING,
			payload: true,
		});

		// Get  response
		const { data } = await services.get(fetchDefaultSeoTypesUrl);

		dispatch({
			type: types.DEFAULT_SEO_TYPES_SUCCESS,
			payload: data,
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: types.DEFAULT_SEO_TYPES_ERROR,
		});
	}
};
