import React from 'react';
import {
	Box,
	Button,
	Card,
	CircularProgress,
	Grid,
	Typography,
} from '@material-ui/core';

// Import utils
import { useInitiateContractProvider } from '../../context';
import { useTranslations } from 'components/utilities';
import { useContractPrivacyPolicy } from 'queries';
import localStorage from 'services/localStorage';

// Import components
import { AlertMessage } from 'components/elements';

// Import helpers
import { INITIATE_CONTRACT_STEPS, LOCAL_STORAGE_CONTRACT } from 'helpers';
import { getConvertedPolicies } from './helpers';

// Import styles
import { useStyles } from './PrivacyPolicy.styles';

export const PrivacyPolicy = () => {
	const { contractNumber } =
		localStorage.storedLsValue(LOCAL_STORAGE_CONTRACT) || {};
	const { data, isFetching, isError } =
		useContractPrivacyPolicy(contractNumber);

	const { setStep } = useInitiateContractProvider();

	const { t } = useTranslations();

	const classes = useStyles();

	const handleOnClick = () => {
		setStep(INITIATE_CONTRACT_STEPS.OTP);
	};

	const convertedPolicies = data && getConvertedPolicies(data);

	if (isFetching) {
		return (
			<Box className={classes.additionalWrapper}>
				<CircularProgress />
			</Box>
		);
	}

	if (isError) {
		return (
			<Box className={classes.additionalWrapper}>
				<AlertMessage
					description={t('common.messages.error_message')}
					title={t('common.error')}
					variant="error"
				/>
			</Box>
		);
	}

	return (
		<Box className={classes.container}>
			<Card className={classes.card}>
				<Grid
					container
					direction="column"
					item
					xs={12}
					spacing={4}
					className={classes.list}
				>
					{convertedPolicies.map(({ description, title }) => (
						<Grid item xs={12} key={title}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Typography className={classes.title}>{t(title)}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography className={classes.description}>
										{description}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Card>
			<Box className={classes.buttonBox}>
				<Button variant="contained" color="primary" onClick={handleOnClick}>
					{t('common.buttons.agree')}
				</Button>
			</Box>
		</Box>
	);
};
