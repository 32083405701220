import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import store
import { updateActiveOffice } from 'store/actions';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import {
	TABLE_ACTIONS_ACCESSORS,
	replaceOfficeDetailsUrl,
	replaceEditOfficeUrl,
} from 'helpers';

// Import components
import { CarsTableCell, StatsTableCell } from '../../components';
import {
	EditTableCell,
	ActiveTableCell,
	NoteTableCell,
} from 'components/elements';

// Import styles
import { useStyles } from '../../styles';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;
const {
	CARS,
	ACTIVE,
	NAME,
	NOTE,
	BOOKINGS,
	BOOKINGS_ACCEPTED,
	AVAILABLE_CARS,
} = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const classes = useStyles();

	const {
		regular_stats: regularStats,
		leasing_stats: leasingStats,
		uuid,
	} = row.original;

	const {
		taken_accepted: leasingTakenAccepted,
		available: leasingAvailable,
		taken: leasingTaken,
	} = leasingStats || {};

	const {
		taken_accepted: regularTakenAccepted,
		available: regularAvailable,
		taken: regularTaken,
	} = regularStats || {};

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		switch (cell.column.id) {
			case NOTE:
				return <NoteTableCell key={cell.column.id} cell={cell} />;

			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditOfficeUrl(uuid)}
					/>
				);

			case NAME:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						isIcon={false}
						originalText={cell.row.values.name}
						editLinkPath={replaceOfficeDetailsUrl(uuid)}
					/>
				);

			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActiveOffice}
						cell={cell}
					/>
				);

			case CARS:
				return <CarsTableCell key={cell.column.id} cell={cell} />;

			case AVAILABLE_CARS:
				return (
					<StatsTableCell
						key={cell.column.id}
						cell={cell}
						regularValue={regularAvailable}
						leasingValue={leasingAvailable}
					/>
				);

			case BOOKINGS:
				return (
					<StatsTableCell
						key={cell.column.id}
						cell={cell}
						regularValue={regularTaken}
						leasingValue={leasingTaken}
					/>
				);

			case BOOKINGS_ACCEPTED:
				return (
					<StatsTableCell
						key={cell.column.id}
						cell={cell}
						regularValue={regularTakenAccepted}
						leasingValue={leasingTakenAccepted}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};
	const setBackGroundColor = (availableCars) => {
		if (availableCars >= 20) {
			return classes.carAvailableIsGood;
		}
		if (availableCars <= 5) {
			return classes.carAvailableIsBad;
		}
		return null;
	};

	return (
		<TableRow
			key={row.id}
			className={setBackGroundColor(regularAvailable)}
			{...row.getRowProps()}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
