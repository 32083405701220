import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FIELDS = (options) => [
	{
		name: 'rent_status',
		type: FIELD_TYPES.SELECT,
		label: 'cars.car_list.plate_form.fields.car_status',
		md: 6,
		required: true,
		options,
		keyExtractor: (row) => row,
		labelExtractor: (row) =>
			i18next.t(`cars.car_list.plate_form.car_status.${row}`),
	},
	{
		name: 'miles_usage',
		type: FIELD_TYPES.NUMBER,
		label: 'cars.car_list.plate_form.fields.miles_usage',
		md: 6,
		required: true,
	},
	{
		name: 'last_open_contract_date',
		type: FIELD_TYPES.DATE,
		label: 'cars.car_list.plate_form.fields.last_opened_contract_date',
		md: 6,
		minDate: false,
	},
];
