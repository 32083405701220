import React from 'react';
import { useSelector } from 'react-redux';

// Import selectors
import { officeRatingSelector } from 'store/selectors/bookings';

// Import utilities
import { useRatingReasons } from 'queries';

export const useOfficeRatingPreview = () => {
	const rating = useSelector(officeRatingSelector);

	const { data: reasons } = useRatingReasons({});

	const reasonUuids =
		rating && rating.rating_reasons ? rating.rating_reasons : [];

	const ratingReasons = React.useMemo(
		() =>
			reasonUuids
				.map((uuid) => reasons.find((reason) => reason.uuid === uuid))
				.filter((reason) => !!reason),
		[reasonUuids, reasons]
	);

	const showRatingReasons = !!ratingReasons.length;

	return {
		rating,
		ratingReasons,
		showRatingReasons,
	};
};
