import services from 'services/services';
import {
	bookingCancelReasonsUrl,
	fetchExtendedBookingsUrl,
	bookingStatesUrl,
} from 'store/paths';
import { combineQueryAndPaginationParams } from 'helpers';

export const fetchExtendedBookings =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const extendedBookingsPromise = services.get(
				fetchExtendedBookingsUrl(params),
				cancelToken
			);

			const cancelReasonsPromise = services.get(
				bookingCancelReasonsUrl,
				cancelToken
			);

			const bookingStatesPromise = services.get(bookingStatesUrl, cancelToken);

			const values = await Promise.all([
				extendedBookingsPromise,
				cancelReasonsPromise,
				bookingStatesPromise,
			]);

			const [{ data }, { data: cancelReasons }, { data: states }] = values;

			const resources = {
				cancelReasons: cancelReasons.data,
				states: states.data,
			};

			return Promise.resolve({ data, resources });
		} catch (error) {
			return Promise.reject(error);
		}
	};
