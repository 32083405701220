import services from 'services/services';

// Import helpers
import {
	changeIsSubscriptionActiveUrl,
	fetchCustomerBalanceUrl,
} from 'store/paths';

export const fetchCustomerBalance =
	({ queryParams, cancelToken, options }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const { data } = await services.post(
				fetchCustomerBalanceUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const changeIsSubscriptionActive = async ({
	subscriptionUuid,
	isActive,
}) => {
	try {
		await services.put(changeIsSubscriptionActiveUrl(subscriptionUuid), {
			is_active: isActive,
		});

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error);
	}
};
