import services from 'services/services';

// Import helpers
import { fetchSubscriptionBalanceUrl } from 'store/paths';

export const fetchSubscriptionBalance =
	({ queryParams, cancelToken, options }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const { data } = await services.post(
				fetchSubscriptionBalanceUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
