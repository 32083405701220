import services from 'services/services';
import {
	choosableRolesUrl,
	fetchCarsUrl,
	updateActiveCarUrl,
	exportCarPlatesFileUrl,
} from 'store/paths';

export const fetchCars =
	({ options, queryParams, itemId, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			// Get response
			const { data } = await services.post(
				fetchCarsUrl(itemId, queryParams),
				body,
				cancelToken
			);

			const {
				data: { data: rolesData },
			} = await services.get(choosableRolesUrl);

			return Promise.resolve({ data, rolesData });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const exportCarPlatesFile =
	({ cancelToken }) =>
	async () => {
		try {
			const { data } = await services.post(
				exportCarPlatesFileUrl,
				{},
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateActiveCar =
	({ isActive, id }) =>
	async () => {
		await services.put(updateActiveCarUrl(id, isActive));
	};
