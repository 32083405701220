import React, { useEffect } from 'react';
import { Typography, Link, Grid } from '@material-ui/core';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';
import { useAppVersion } from 'queries';

// Import styles
import { useStyles } from './styles';

export const Footer = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { message } = useMessage();

	const { data: appVersion, errorMessage, isError } = useAppVersion();

	useEffect(() => {
		if (isError) {
			message.error(errorMessage);
		}
		// eslint-disable-next-line
	}, [isError]);

	return (
		<Grid container className={classes.root}>
			<Grid item>
				<Typography variant="body1">
					&copy; Copyright:
					<Link
						component="a"
						className={classes.link}
						href="https://telgani.com/"
						target="_blank"
					>
						Telgani
					</Link>
					2020
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body1">
					{t('common.api_version', { version: appVersion?.api?.version })}
				</Typography>
			</Grid>
		</Grid>
	);
};
