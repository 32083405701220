import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { Button, Grid } from '@material-ui/core';

// Import utils and helpers
import { useAddCorporateCustomerDialogProvider } from 'views/b2b/CorporateOverview/components/AddCorporateCustomerDialog/context';
import { useCommonFields, useTranslations } from 'components/utilities';
import { CLICKED_BUTTON_NAME } from '../../../../helpers';
import { ADD_CUSTOMER_STEP_FIELDS } from './helpers';

// Import components
import { ButtonProgress } from 'components/elements';
import { InfoLabel } from '../../components';

export const FormContent = () => {
	const {
		setSaveAndAddCustomerButtonLoading,
		setSaveAndBookCarButtonLoading,
		setClickedButtonName,
		isSaveAndAddCustomerButtonLoading,
		isSaveAndBookCarButtonLoading,
		toggleDialog,
	} = useAddCorporateCustomerDialogProvider();
	const { submitting, submitErrors } = useFormState();
	const { renderFields } = useCommonFields();
	const { change, submit } = useForm();
	const { t } = useTranslations();

	const fileName = 'license';
	const fileUploaderError = submitErrors?.[fileName]?.[0];

	const handleOnFileUploader = (file) => {
		change(fileName, file);
	};

	const handleOnSaveAndBookACarButton = async () => {
		setClickedButtonName(CLICKED_BUTTON_NAME.ADD_CUSTOMER_AND_BOOK_CAR);
		setSaveAndBookCarButtonLoading(true);
		await submit();
	};

	const handleOnSaveAndAddCustomerButton = async () => {
		setClickedButtonName(CLICKED_BUTTON_NAME.ADD_CUSTOMER);
		setSaveAndAddCustomerButtonLoading(true);
		await submit();
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<InfoLabel />
			</Grid>
			{ADD_CUSTOMER_STEP_FIELDS({
				handleOnFileUploader,
				fileUploaderError,
			}).map(renderFields)}
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={2}>
						<Button
							onClick={toggleDialog}
							variant="outlined"
							color="primary"
							fullWidth
						>
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item xs={5}>
						<ButtonProgress
							fullWidth
							variant="outlined"
							color="primary"
							onClick={handleOnSaveAndBookACarButton}
							isLoading={isSaveAndBookCarButtonLoading}
							disabled={isSaveAndBookCarButtonLoading || submitting}
						>
							{t('common.buttons.save_and_book_a_car')}
						</ButtonProgress>
					</Grid>
					<Grid item xs={5}>
						<ButtonProgress
							fullWidth
							variant="contained"
							color="primary"
							onClick={handleOnSaveAndAddCustomerButton}
							isLoading={isSaveAndAddCustomerButtonLoading}
							disabled={isSaveAndAddCustomerButtonLoading || submitting}
						>
							{t('common.buttons.save_and_add_new')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
