import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { KIOSK_AIRPORTS_LIST_QUERY } from 'helpers';

const useAirportList = (body = {}) => {
	const path = `/v2/airport/terminal/nearest/collection`;

	const { data, isError, isFetching } = useQuery(
		KIOSK_AIRPORTS_LIST_QUERY,
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};

export default useAirportList;
