import React from 'react';

// Import helpers
import { BOOKING_CHIP_REFERENCES } from 'helpers';

// Import components
import BookingCountChip from '../BookingCountChip';

const ValidationCountChip = () => {
	return <BookingCountChip reference={BOOKING_CHIP_REFERENCES.VALIDATION} />;
};

export default ValidationCountChip;
