// Import components
import { TextFieldAdapter } from 'components/elements';

export const LEASING_CAR_DETAILS_FIELDS = () => [
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'cars.form.details.description_en',
		name: `description_en`,
		md: 6,
		xs: 12,
		rows: 4,
		isRequired: false,
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'cars.form.details.description_ar',
		name: `description_ar`,
		md: 6,
		xs: 12,
		rows: 4,
		isRequired: false,
	},
];

export const LEASING_CAR_INITIAL_VALUES = {
	carInfo: {
		details: {
			description: {
				ar: null,
				en: null,
			},
		},
		vehicle: {
			maker_name: {
				en: '',
				ar: '',
			},
			model_name: {
				en: '',
				ar: '',
			},
			year: '',
			uuid: '',
		},
		specification: {
			miles_usage: null,
			fuel_type: null,
			doors_number: null,
			used_oil: null,
			seat_material: null,
			front_camera: false,
			rear_camera: false,
			sensors: false,
			panorama: false,
			usb: false,
			bluetooth: false,
			apple_car_pay: false,
			cruise_control: false,
			self_driving_car: false,
		},
	},
	prices: {
		base_rent: {
			months: {
				m1: {
					price: null,
					period: 1,
				},
				m12: {
					price: null,
					period: 12,
				},
				m24: {
					price: null,
					period: 24,
				},
				m36: {
					price: null,
					period: 36,
				},
			},
		},
		security_deposit: {
			one_time: null,
		},
		leasing_sell_price: {
			is_active: false,
			one_time: null,
		},
		extra_km: {
			kilometers: {
				k1: {
					price: null,
					period: 1,
				},
			},
		},
		deductible_amount: {
			one_time: null,
		},
		full_tank: {
			one_time: null,
		},
		unlimited_kms: {
			is_unlimited: false,
			one_time: null,
		},
		insurance: {
			months: {
				m1: {
					is_active: false,
					price: null,
					period: 1,
				},
			},
		},
		no_smoking: {
			months: {
				m1: {
					is_active: false,
					price: null,
					period: 1,
				},
			},
		},
	},
	plate: null,
	plate_type: null,
};
