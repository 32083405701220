import { useTranslations } from 'components/utilities';
import { useSelector } from 'react-redux';

// Import selectors
import { isAdminSelector } from 'store/selectors';

export const useDiscountPreviewField = (data) => {
	const {
		amount_gross: amountGross,
		max_amount: maxAmount,
		percentage,
		type,
		code,
	} = data;
	const { t } = useTranslations();

	const isAdmin = useSelector(isAdminSelector);

	const discountPrice = +amountGross;

	const title = t('bookings.preview_booking.invoice.total_discount');

	const discountInfoForAdmin = `(${percentage}% ${type} "${code}", ${t(
		'bookings.preview_booking.invoice.max_discount'
	)} ${maxAmount} ${t('currency.sar')})`;

	const discountInfoForOthers = `${t(
		'bookings.preview_booking.invoice.pre_paid'
	)}`;

	const discountInfo = isAdmin ? discountInfoForAdmin : discountInfoForOthers;

	const label = discountPrice
		? isAdmin
			? title + discountInfo
			: discountInfo
		: title;

	let fixedDiscount = discountPrice.toFixed(2);

	if (discountPrice) fixedDiscount = `-${fixedDiscount}`;

	const color = discountPrice ? 'error' : 'initial';

	return {
		label,
		fixedDiscount,
		color,
	};
};
