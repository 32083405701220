import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { URGENT_REASONS_QUERY } from 'helpers';

const useUrgentReasons = () => {
	const url = `/v2/booking/urgent-assistance-reasons`;

	const { data, isFetching } = useQuery(
		URGENT_REASONS_QUERY,
		async () => await services.get(url),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};

export default useUrgentReasons;
