import React from 'react';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { profileDataSelector } from 'store/selectors';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

// Import components
import { ToggleButton, ProfileModal } from './components';

export const ProfileToggle = ({ ...props }) => {
	const { full_name, role } = useSelector(profileDataSelector);

	return (
		<PopupState variant="popover" popupId="demo-popup-menu" {...props}>
			{(popupState) => (
				<Box>
					<ToggleButton
						role={role}
						fullName={full_name}
						bindTrigger={bindTrigger(popupState)}
					/>
					<ProfileModal
						fullName={full_name}
						role={role}
						bindPopover={bindPopover(popupState)}
					/>
				</Box>
			)}
		</PopupState>
	);
};
