import React, { createContext, useEffect, useState } from 'react';
import { node } from 'prop-types';

// Import utilities
import { useTableContextProvider } from 'components/context';
import { useUrgentBookingsStats } from 'queries';

// Import helpers
import { getOptions } from '../helpers';

export const StatsProviderContext = createContext();

export const StatsContextProvider = ({ children }) => {
	const [stats, setStats] = useState({});

	const { filters, data: tableData } = useTableContextProvider();

	const options = getOptions(filters);

	const { data, isLoading, isStale, isError, error, refetch } =
		useUrgentBookingsStats(options);

	useEffect(() => {
		isStale && tableData.length && refetch();

		// eslint-disable-next-line
	}, [tableData]);

	useEffect(() => {
		if (data) {
			const {
				total: review,
				current_month: { solved, unsolved, avg_time: avgTime },
				last_month: { avg_time: prevAvgTime },
				top_reason: topReasons,
				top_company: topCompanies,
			} = data;

			const total = Number(review) + Number(solved) + Number(unsolved);

			const percentSolved = Math.round((solved / total) * 100) || 0;

			setStats({
				total,
				topCompanies,
				topReasons,
				currentAvg: avgTime,
				prevAvg: prevAvgTime,
				solved: percentSolved,
			});
		}
	}, [data]);

	return (
		<StatsProviderContext.Provider value={{ isLoading, isError, error, stats }}>
			{children}
		</StatsProviderContext.Provider>
	);
};

StatsContextProvider.propTypes = {
	children: node,
};
