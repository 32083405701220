import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { EDIT_BOOKING_HISTORY_QUERY } from 'helpers';

const useEditBookingHistory = ({ uuid, enabled = true }) => {
	const path = `/v2/booking/${uuid}/edit/history`;

	const { data, isFetching, isError, error } = useQuery(
		[EDIT_BOOKING_HISTORY_QUERY, uuid],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching, isError, error };
};

export default useEditBookingHistory;
