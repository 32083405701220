import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { bool, string } from 'prop-types';

// Import utilities and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { multiSelectLabelExtractor } from './helpers';
import { useCompanies } from 'queries';
import { FIELD_TYPES } from 'helpers';

export const CompaniesFilter = ({ label, name, required }) => {
	const { data: companies, isFetching } = useCompanies();
	const { renderFields } = useCommonFields();
	const { values } = useFormState();
	const {
		i18n: { language: lang },
	} = useTranslations();

	if (!companies.length) {
		return null;
	}

	return (
		<Grid container item xs={3} md={3}>
			{isFetching ? (
				<Grid item xs>
					<CircularProgress size={24} />
				</Grid>
			) : (
				renderFields({
					type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
					currentMultiSelectValues: values?.[name],
					labelExtractor: ({ name }) => name[lang],
					keyExtractor: ({ uuid }) => uuid,
					searchExtractor: 'children[1]',
					multiSelectLabelExtractor: (options, currentUuid) =>
						multiSelectLabelExtractor(options, currentUuid, lang),
					showAllOption: true,
					options: companies,
					label,
					name,
					required,
				})
			)}
		</Grid>
	);
};

CompaniesFilter.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
	required: bool.isRequired,
};
