import { LOCAL_STORAGE_USER } from 'helpers';

const storedLsValue = (name = LOCAL_STORAGE_USER) => {
	return JSON.parse(window.localStorage.getItem(name)); // Init
};

const setLsValue = (value, name = LOCAL_STORAGE_USER) => {
	const valueToStore =
		value instanceof Function ? value(storedLsValue()) : value;
	window.localStorage.setItem(name, JSON.stringify(valueToStore));
};

const removeLsValue = (name = LOCAL_STORAGE_USER) => {
	window.localStorage.removeItem(name);
};

export default {
	storedLsValue,
	setLsValue,
	removeLsValue,
};
