// Import helpers
import { capitalize } from 'helpers';

export const ADMIN = 'admin';

export const SUPPLIER_ADMIN = 'supplier_admin';

export const COMPANY_MANAGER = 'company_manager';

export const COMPANY_WORKER = 'company_worker';

export const OFFICE_MANAGER = 'office_manager';

export const OFFICE_WORKER = 'office_worker';

export const DRIVER = 'driver';

export const CORPORATE_COORDINATOR = 'corporate_coordinator';

export const ADMINS = [ADMIN, SUPPLIER_ADMIN];

export const COMPANY_MANAGERS = [COMPANY_MANAGER, ...ADMINS];

export const COMPANY_MANAGERS_WITHOUT_ADMINS = [
	COMPANY_MANAGER,
	COMPANY_WORKER,
	OFFICE_MANAGER,
	OFFICE_WORKER,
];

export const COMPANY_WORKERS = [COMPANY_WORKER, ...COMPANY_MANAGERS];

export const COMPANY_WORKERS_WITHOUT_PARENTS = [
	COMPANY_WORKER,
	COMPANY_MANAGER,
];

export const OFFICE_MANAGERS = [OFFICE_MANAGER, ...COMPANY_WORKERS];
export const OFFICE_MANAGERS_WITH_CORPORATE_COORDINATOR = [
	OFFICE_MANAGER,
	CORPORATE_COORDINATOR,
	...COMPANY_WORKERS,
];
export const ADMINS_WITH_CORPORATE_COORDINATOR = [
	CORPORATE_COORDINATOR,
	...ADMINS,
];

export const OFFICE_WORKERS_WITHOUT_PARENTS = [OFFICE_WORKER, OFFICE_MANAGER];

export const convertRoleToDisplay = (role) => {
	const splitedRole = role.replace('_', ' ').split(' ');
	const convertedRole = splitedRole.map((item) => capitalize(item)).join(' ');
	return convertedRole;
};

export const convertRoleToSnakeCase = (role) =>
	role.toLowerCase().replace(' ', '_');

export const getRole = (userRole) => {
	switch (userRole) {
		case COMPANY_MANAGER:
		case 'Company Manager':
			return 'common.roles.company_manager';

		case OFFICE_MANAGER:
		case 'Office Manager':
			return 'common.roles.office_manager';

		case DRIVER:
			return 'common.roles.driver';

		default:
			return '';
	}
};

export const ROLE_FILTER_OPTIONS = [
	{
		value: 'all',
		label: 'common.all',
	},
	{
		value: COMPANY_MANAGER,
		label: 'common.roles.company_manager',
	},
	{
		value: OFFICE_MANAGER,
		label: 'common.roles.office_manager',
	},
	{
		value: DRIVER,
		label: 'common.roles.driver',
	},
];
