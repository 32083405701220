import { enGB, arSA } from 'date-fns/locale';
import { format } from 'date-fns';
import moment from 'moment-hijri';
import * as momentFullPackage from 'moment';
import i18next from 'i18next';

// Import helpers
import { EN } from './variables';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_SLASH_DATE_FORMAT = 'dd/MM/yyyy';
export const YMD_DATE_FORMAT = 'yyyy/MM/dd';
export const YMDTHM_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm";
export const YMDHM_DATE_FORMAT = 'yyyy-MM-dd hh:mm';
export const DEFAULT_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS_FORMAT =
	'yyyy-MM-dd HH:mm:ss';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const YMD_SLASH_DATE_FORMAT = 'YYYY/MM/DD';
export const YMD_SLASH_HIJRI_DATE_FORMAT = 'iYYYY/iMM/iDD';
export const YMDTHM_DATE_MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm';
export const YM_HIJRI_DATE_FORMAT = 'iYYYY-iM';
export const YM_DATE_FORMAT = 'YYYY-M';
export const DLY_DATE_FORMAT = 'dd LLL yyyy';
export const HYPHEN_DATE_FORMAT = 'YYYY-MM-DD';

export const getMonthName = (monthNumber) => {
	const locale = i18next.language === EN ? enGB : arSA;
	return format(new Date(2000, monthNumber - 1, 1), 'MMMM', { locale });
};

export const convertToGregorianDate = (
	date,
	format = YMD_SLASH_DATE_FORMAT
) => {
	const isHijriDate = moment(date, YMD_SLASH_HIJRI_DATE_FORMAT, true).isValid();
	const gregorianDate = moment(
		date,
		isHijriDate ? YMD_SLASH_HIJRI_DATE_FORMAT : YMD_SLASH_DATE_FORMAT
	).format(format);

	return gregorianDate;
};

export const isTimeInPast = (time) => {
	const currentTime = momentFullPackage();
	const targetTime = momentFullPackage(time, DEFAULT_TIME_FORMAT);
	return targetTime.isBefore(currentTime);
};

export const checkIfTimeIsBeforeCurrentTime = ({ time, beforeInMin = 0 }) => {
	const currentTime = momentFullPackage();
	const targetTime = momentFullPackage(time, DEFAULT_TIME_FORMAT);
	const timeDifference = targetTime.diff(currentTime, 'minutes');

	if (timeDifference < 0 || isTimeInPast(time)) {
		return false;
	}

	return timeDifference < beforeInMin;
};
