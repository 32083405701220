import React from 'react';
import PropTypes from 'prop-types';

// Import components
import {
	FullArabicNamePreviewField,
	FullNamePreviewField,
	LicensePreviewField,
	BookingStatusPreviewField,
	PickUpAddressPreviewField,
	OptionsPreviewField,
	OptionsPricesPreviewField,
	PaymentMethodPreviewField,
	DefaultPreviewField,
	ContractDatesPreviewField,
	DiscountPreviewField,
	CustomerWalletPreviewField,
	OriginalDropDatePreviewField,
	NewDropDatePreviewField,
	TotalExtensionAmountPreviewField,
	PaymentMethodsPreviewField,
	SubscriptionInvoicePaymentMethodPreviewField,
	ViewAttachmentPreviewField,
	PayerPreviewField,
	DropDatePreviewField,
	DropDateAfterEarlyReturnPreviewField,
	DaysAfterEarlyReturnPreviewField,
	DaysPreviewField,
	OldDeliveryInformationPreviewField,
	DividedTotalGrossAmountPreviewField,
} from '../components';
import { InvoiceOptionsPreviewField } from './InvoiceOptionsPreviewField';
import RoleProtected from 'components/hoc/RoleProtected';

// Import helpers
import {
	FIELDS_ACCESSORS,
	PERMISSION_CUSTOMER_WALLET_PREVIEW,
} from '../helpers';

const PreviewFieldFactory = (props) => {
	switch (props.accessor) {
		case FIELDS_ACCESSORS.SUBSCRIPTION_INVOICE_PAYMENT_METHOD:
			return <SubscriptionInvoicePaymentMethodPreviewField {...props} />;
		case FIELDS_ACCESSORS.PAYMENT_METHOD:
			return <PaymentMethodPreviewField {...props} />;
		case FIELDS_ACCESSORS.PAYMENT_METHODS:
			return <PaymentMethodsPreviewField {...props} />;
		case FIELDS_ACCESSORS.OPTIONS_PRICES:
			return <OptionsPricesPreviewField {...props} />;
		case FIELDS_ACCESSORS.PICK_UP_ADDRESS:
			return <PickUpAddressPreviewField {...props} />;
		case FIELDS_ACCESSORS.OPTIONS:
			return <OptionsPreviewField {...props} />;
		case FIELDS_ACCESSORS.INVOICE_OPTIONS:
			return <InvoiceOptionsPreviewField {...props} />;
		case FIELDS_ACCESSORS.BOOKING_STATUS:
			return <BookingStatusPreviewField {...props} />;
		case FIELDS_ACCESSORS.LICENSE:
			return <LicensePreviewField {...props} />;
		case FIELDS_ACCESSORS.FULL_NAME:
			return <FullNamePreviewField {...props} />;
		case FIELDS_ACCESSORS.EXTENDED_DROP_DATE:
			return <OriginalDropDatePreviewField {...props} />;
		case FIELDS_ACCESSORS.FULL_ARABIC_NAME:
			return <FullArabicNamePreviewField {...props} />;
		case FIELDS_ACCESSORS.CONTRACT_DATES:
			return <ContractDatesPreviewField {...props} />;
		case FIELDS_ACCESSORS.DISCOUNT:
			return <DiscountPreviewField {...props} />;
		case FIELDS_ACCESSORS.WALLET:
			return (
				<RoleProtected roles={PERMISSION_CUSTOMER_WALLET_PREVIEW}>
					<CustomerWalletPreviewField {...props} />
				</RoleProtected>
			);
		case FIELDS_ACCESSORS.NEW_DROP_DATE:
			return <NewDropDatePreviewField {...props} />;
		case FIELDS_ACCESSORS.TOTAL_EXTENSION_AMOUNT:
			return <TotalExtensionAmountPreviewField {...props} />;
		case FIELDS_ACCESSORS.VIEW_ATTACHMENT:
			return <ViewAttachmentPreviewField {...props} />;

		case FIELDS_ACCESSORS.PAYER:
			return <PayerPreviewField {...props} />;

		case FIELDS_ACCESSORS.DROP_DATE:
			return <DropDatePreviewField />;

		case FIELDS_ACCESSORS.DROP_DATE_AFTER_EARLY_RETURN:
			return <DropDateAfterEarlyReturnPreviewField />;

		case FIELDS_ACCESSORS.DAYS_AFTER_EARLY_RETURN:
			return <DaysAfterEarlyReturnPreviewField />;

		case FIELDS_ACCESSORS.DAYS:
			return <DaysPreviewField />;

		case FIELDS_ACCESSORS.OLD_DELIVERY_INFORMATION:
			return <OldDeliveryInformationPreviewField {...props} />;

		case FIELDS_ACCESSORS.DIVIDED_TOTAL_GROSS_AMOUNT:
			return <DividedTotalGrossAmountPreviewField {...props} />;

		default:
			return <DefaultPreviewField {...props} />;
	}
};

PreviewFieldFactory.propTypes = {
	accessor: PropTypes.string,
};

export default PreviewFieldFactory;
