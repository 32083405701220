import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Import helpers
import {
	BOOKING_STATES,
	BOOKING_EXTENSION_STATUS,
	getDeliveryAvailabilityStates,
} from 'helpers';

// Import services
import services from 'services/services';

// Import utilities
import {
	useMessage,
	useProfile,
	useToggle,
	useTranslations,
} from 'components/utilities';
import { fetchBookingPreviewUrl } from 'store/paths';
import {
	acceptBooking,
	approveBookingCancellation,
	approveBookingExtension,
	rejectBookingExtension,
} from 'store/actions';

const useBookingStatusActions = ({ booking, onChange }) => {
	const { on: isAssignDriverDialogOpen, toggle: toggleAssignDriverDialog } =
		useToggle();

	const [isCancellation, setIsCancellation] = useState(false);
	const [isRejectExtensionLoading, setIsRejectExtensionLoading] =
		useState(false);
	const [isAcceptExtensionLoading, setIsAcceptExtensionLoading] =
		useState(false);
	const [isRejection, setIsRejection] = useState(false);
	const [error, setError] = useState('');

	const { isCorporateCoordinator } = useProfile();
	const dispatch = useDispatch();

	const { pathname } = useLocation();

	const { message } = useMessage();

	const { t } = useTranslations();

	const {
		state,
		can_cancel,
		can_accept,
		can_approve_reject_booking,
		extension,
		uuid,
	} = booking;

	const { extension_uuid: extensionUuid, extension_state: extensionStatus } =
		extension || {};

	const isExtensionStatusPending =
		extensionStatus === BOOKING_EXTENSION_STATUS.PENDING;

	const canCancel =
		can_cancel &&
		state === BOOKING_STATES.ACCEPTED &&
		!isCorporateCoordinator &&
		!isExtensionStatusPending;
	const canReject = state === BOOKING_STATES.PENDING && !isCorporateCoordinator;
	const canAccept = can_accept && !isCorporateCoordinator;

	const isExtensionDialog =
		isExtensionStatusPending && pathname.includes('preview');

	const canApproveCancellation =
		can_approve_reject_booking &&
		(state === BOOKING_STATES.CANCELED || state === BOOKING_STATES.REJECTED);

	const isButtonDisabled = isRejectExtensionLoading || isAcceptExtensionLoading;

	const handleCancel = () => {
		setIsCancellation(true);
	};

	const handleReject = () => {
		setIsRejection(true);
	};

	const handleAccept = async () => {
		try {
			const bookingPreview = await services.get(fetchBookingPreviewUrl(uuid));
			const {
				pickupDeliveryAvailableAndNotAssigned,
				dropDeliveryAvailableAndNotAssigned,
			} = getDeliveryAvailabilityStates(bookingPreview.data.data.delivery);

			if (
				pickupDeliveryAvailableAndNotAssigned ||
				dropDeliveryAvailableAndNotAssigned
			) {
				toggleAssignDriverDialog();
			} else {
				await acceptBooking({
					bookingId: uuid,
				});
				onChange();
			}
		} catch (error) {
			setError(error.data ? error.data.message : '');
		}
	};

	const handleApproveCancellation = async () => {
		try {
			await approveBookingCancellation({
				bookingId: booking.uuid,
			});

			onChange();
		} catch (error) {
			setError(error.data ? error.data.message : '');
		}
	};

	const handleAcceptExtension = async () => {
		setIsAcceptExtensionLoading(true);
		try {
			await approveBookingExtension({ extensionUuid })(dispatch);

			setIsAcceptExtensionLoading(false);

			onChange();

			message.success(
				t(`bookings.preview_booking.booking_info.messages.accept_success`)
			);
		} catch (error) {
			setIsAcceptExtensionLoading(false);

			message.error(t(`common.messages.error_message`));
		}
	};

	const handleRejectExtension = async () => {
		setIsRejectExtensionLoading(true);
		try {
			await rejectBookingExtension({ extensionUuid })(dispatch);

			setIsRejectExtensionLoading(false);

			onChange();

			message.success(
				t(`bookings.preview_booking.booking_info.messages.reject_success`)
			);
		} catch (error) {
			setIsRejectExtensionLoading(false);
			message.error(t(`common.messages.error_message`));
		}
	};

	const handleChange = () => {
		handleClose();
		onChange();
	};

	const handleClose = () => {
		setIsCancellation(false);
		setIsRejection(false);
	};

	const clearError = () => {
		setError('');
	};

	return {
		canCancel,
		canAccept,
		canReject,
		canApproveCancellation,
		handleCancel,
		handleAccept,
		handleReject,
		handleApproveCancellation,
		handleChange,
		handleClose,
		handleAcceptExtension,
		handleRejectExtension,
		toggleAssignDriverDialog,
		error,
		clearError,
		uuid,
		isRejectExtensionLoading,
		isAcceptExtensionLoading,
		isExtensionStatusPending,
		isCorporateCoordinator,
		isButtonDisabled,
		isExtensionDialog,
		isCancellation,
		isRejection,
		isAssignDriverDialogOpen,
	};
};

export default useBookingStatusActions;
