import { combineQueryAndPaginationParams } from 'helpers';
import services from 'services/services';
import {
	bookingCancelReasonsUrl,
	fetchScheduledBookingsUrl,
	updateDoneLateDeliveryBookingUrl,
	updateStatusScheduleBookingUrl,
} from 'store/paths';

export const fetchScheduledBookings =
	({ options, queryParams, cancelToken, isLateDelivery = 0 }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const scheduledBookingsPromise = services.get(
				fetchScheduledBookingsUrl(
					`${params}&is_late_delivery=${isLateDelivery}`
				),
				cancelToken
			);

			const cancelReasonsPromise = services.get(
				bookingCancelReasonsUrl,
				cancelToken
			);

			const values = await Promise.all([
				scheduledBookingsPromise,
				cancelReasonsPromise,
			]);

			const [{ data }, { data: cancelReasons }] = values;

			const resources = { cancelReasons: cancelReasons.data };

			return Promise.resolve({ data, resources });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateStatusScheduleBooking =
	({ isAccepted, id, cancelId }) =>
	async () => {
		try {
			const deleteOption = {
				cancel_reason: {
					uuid: cancelId,
				},
			};
			const options = isAccepted ? deleteOption : {};

			// Get response
			await services.put(
				updateStatusScheduleBookingUrl(id, isAccepted),
				options
			);
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	};

export const updateDoneLateDeliveryBooking = (deliveryUuid) => async () => {
	await services.patch(updateDoneLateDeliveryBookingUrl(deliveryUuid));
};
