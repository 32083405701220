import React from 'react';
import { Link, Button, Grid, Typography } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';
import { bool, func } from 'prop-types';

// Import utils
import { useCommonFields, useTranslations } from 'components/utilities';

import { usePaymentProvider } from '../../../Payment/context';

// import helpers
import { INPUT_FIELDS, OFFLINE_SETTLEMENT_FIELDS } from './helpers';

// Import components
import PreviewFields from 'views/Bookings/PreviewBooking/components/PreviewFields';
import { ButtonProgress, FileUploader } from 'components/elements';

// Import styles
import { useStyles } from './FormContent.styles';

export const FormContent = ({ toggle, readOnly, isLoading }) => {
	const { dialogCustomData } = usePaymentProvider();
	const { submitting, values } = useFormState();
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const { change } = useForm();
	const classes = useStyles();

	const onSuccessCallback = (file) => {
		change('file', file);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<PreviewFields
					fields={OFFLINE_SETTLEMENT_FIELDS}
					customData={dialogCustomData}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					{INPUT_FIELDS({ readOnly, t }).map(renderFields)}
					<Grid item xs={12}>
						{readOnly ? (
							values.file && (
								<Grid container>
									<Grid item xs={12}>
										<Typography className={classes.label}>
											{t(
												'bookings.preview_booking.subscription_invoice.wire_transfer'
											)}
										</Typography>
									</Grid>
									<Link
										color="primary"
										href={values.file}
										download="wire_transfer"
										target="_blank"
									>
										{t('common.buttons.download')}
									</Link>
								</Grid>
							)
						) : (
							<FileUploader
								label="bookings.preview_booking.subscription_invoice.wire_transfer"
								onSuccessCallback={onSuccessCallback}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end" spacing={2}>
					<Grid item>
						<Button onClick={toggle} color="primary" variant="outlined">
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					{!readOnly && (
						<Grid item>
							<ButtonProgress
								type="submit"
								color="primary"
								variant="contained"
								circularProgressSize={24}
								isLoading={isLoading || submitting}
								disabled={isLoading || submitting}
							>
								{t('common.buttons.settle')}
							</ButtonProgress>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	toggle: func.isRequired,
	isLoading: bool,
	readOnly: bool,
};
