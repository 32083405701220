import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Import utils and helpers
import { useProfile, useTranslations } from 'components/utilities';
import { fetchBookingPreview } from 'store/actions';
import { useEditBookingHistory } from 'queries';

// Import store
import { previewSelector } from 'store/selectors';

const usePreviewBooking = () => {
	const { t } = useTranslations();
	const { isCorporateCoordinator } = useProfile();
	const dispatch = useDispatch();
	const { id } = useParams();
	const { isAdmin } = useProfile();

	const { item, isLoaded, isError, error } = useSelector(previewSelector);

	const { data, isFetching: isFetchingEditBooking } = useEditBookingHistory({
		uuid: id,
		enabled: isAdmin,
	});

	const isHistory = data?.length;

	const editBookingHistory = isHistory ? data?.[0] : null;

	const companyAndCarLabel = isHistory
		? t(`bookings.preview_booking.new_company_and_car_card`)
		: t(`bookings.preview_booking.company_and_car_card`);

	const subscriptionUuid = item.subscription?.uuid;

	useEffect(() => {
		const source = axios.CancelToken.source();
		const cancelToken = source.token;
		fetchBookingPreview({ id, cancelToken, isCorporateCoordinator })(dispatch);

		return () => {
			source.cancel();
		};
		// eslint-disable-next-line
	}, []);

	return {
		item,
		error,
		companyAndCarLabel,
		editBookingHistory,
		subscriptionUuid,
		isFetchingEditBooking,
		isHistory,
		isLoaded,
		isError,
	};
};

export default usePreviewBooking;
