import React from 'react';
import { Grid } from '@material-ui/core';
import { fetchPlateFormResources, submitPlateForm } from 'store/actions';

// Import components
import { CarPlate, CarPlateDetails } from '../components';

// Import helpers
import { plateFormSelector } from 'store/selectors';
import { URLS } from 'components/routes';

// Import utilities
import { FormRPC, useMessage } from 'components/utilities';
import { usePlateFormOptions } from 'queries';

//Import styles
import useStyles from '../styles';
import { yupValidator } from 'helpers';

// Import helpers
import { validationSchema } from './validation';

export const AddPlate = () => {
	const { data } = usePlateFormOptions();

	const classes = useStyles();

	const { body_colors, rent_statuses } = data || {};

	const rentStatuses = Object.values(rent_statuses || {});
	const bodyColors = Object.entries(body_colors || {});

	const { message } = useMessage();

	const errorCallback = (errorMessage) => {
		message.error(errorMessage);
	};

	return (
		<div className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchPlateFormResources}
				submitFormAction={(props) =>
					submitPlateForm({ ...props, errorCallback })
				}
				goBackPath={URLS.carListUrl}
				store={plateFormSelector}
				isCardLayout={false}
				noValidate
				lg={10}
				validation={(values) =>
					yupValidator({ values, validationSchema: validationSchema() })
				}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={10} xs={12}>
							<CarPlate bodyColors={bodyColors} />
						</Grid>
						<Grid item lg={10} xs={12}>
							<CarPlateDetails rentStatuses={rentStatuses} />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</div>
	);
};
