import React from 'react';
import { bool } from 'prop-types';
import { Field } from 'react-final-form';
import { Grid, MenuItem } from '@material-ui/core';

// Import helpers
import { FIELD_TYPES } from './helpers';

// Import utils
import { useSettingsFields } from './useSettingsFields';
import { useTranslations } from 'components/utilities';

// Import components
import { DateRangeInput } from 'components/elements';

export const SettingsFields = ({ submitting }) => {
	const { t } = useTranslations(null, {
		keyPrefix: 'employee_rewarding.form.fields',
	});

	const { SETTINGS_FIELDS } = useSettingsFields();

	return (
		<>
			{SETTINGS_FIELDS.map(
				({ label, name, component, type, options, nameFrom, nameUntil }) => (
					<Grid key={name || label} item xs={12} md={4}>
						{type === FIELD_TYPES.SELECT && (
							<Field
								margin="dense"
								variant="outlined"
								fullWidth
								name={name}
								component={component}
								label={t(label)}
								disabled={submitting}
								type={type}
								required
							>
								{options.map(({ label, value }) => (
									<MenuItem key={value} value={value}>
										{label}
									</MenuItem>
								))}
							</Field>
						)}
						{type === FIELD_TYPES.DATE_RANGE && (
							<DateRangeInput
								nameUntil={nameUntil}
								nameFrom={nameFrom}
								combinedInputs
								label={t(label)}
							/>
						)}
					</Grid>
				)
			)}
		</>
	);
};

SettingsFields.propTypes = {
	submitting: bool.isRequired,
};
