import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import helpers
import {
	BOOKING_URGENT_STATES,
	replaceEditBookingUrl,
	replaceOfficeDetailsUrl,
	replacePreviewBookingUrl,
} from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import {
	LinkTableCell,
	ValidationActionTableCell,
	UrgentStatusTableCell,
	ActionsTableCell,
	NoteTableCell,
	ApprovedTableCell,
	CompensatedTableCell,
	RatingReasonTableCell,
	SettlementStatusTableCell,
} from 'components/elements';

import { useTranslations } from 'components/utilities';

const {
	BOOKING_ID,
	OFFICE_NAME,
	NOTE,
	DURATION,
	APPROVED,
	COMPENSATED,
	RATING_REASON,
	SETTLEMENT_STATUS,
	CUSTOMER_NAME,
	PICK_DATE,
	CREATED_AT,
	ACTION,
	URGENT_STATUS,
	URGENT_REASON,
	ACTIONS,
} = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const { t, i18n } = useTranslations();
	const lng = i18n.language;

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case NOTE:
				return <NoteTableCell key={cell.column.id} cell={cell} />;

			case ACTIONS:
				return (
					<ActionsTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditBookingUrl(cell.row.original.uuid)}
						canEdit
					/>
				);

			case OFFICE_NAME:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceOfficeDetailsUrl(cell.row.original.office_uuid)}
					>
						{cell.row.original.office_name}
					</LinkTableCell>
				);
			case BOOKING_ID:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePreviewBookingUrl(cell.row.original.uuid)}
					>
						{cell.row.original.individual_number}
					</LinkTableCell>
				);
			case DURATION:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.response_time &&
							`${cell.row.original.response_time} ${t(
								'bookings.table.common.minutes'
							)}`}
					</TableCell>
				);
			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.customer_name}
						<br /> {cell.row.original.customer_phone}
					</TableCell>
				);
			case PICK_DATE:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.pick_date}
						<br /> {cell.row.original.pick_time}
					</TableCell>
				);
			case CREATED_AT: {
				const [date, time] = cell.row.original?.created_at.split(' ');
				return (
					<TableCell key={cell.column.id}>
						{date}
						<br />
						{time}
					</TableCell>
				);
			}
			case URGENT_REASON:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.urgentAssistance.name[lng]}
					</TableCell>
				);

			case APPROVED:
				return <ApprovedTableCell key={cell.column.id} cell={cell} />;
			case COMPENSATED:
				return <CompensatedTableCell key={cell.column.id} cell={cell} />;
			case RATING_REASON:
				return <RatingReasonTableCell key={cell.column.id} cell={cell} />;
			case SETTLEMENT_STATUS:
				return <SettlementStatusTableCell key={cell.column.id} cell={cell} />;

			case ACTION:
				return cell.row.original.urgent_assistance_status ===
					BOOKING_URGENT_STATES.REVIEW ? (
					<ValidationActionTableCell
						key={cell.column.id}
						cell={cell}
						actionButtonTitle={t('validation.table.action.mark')}
					/>
				) : (
					<TableCell key={cell.column.id} {...cell.getCellProps()} />
				);
			case URGENT_STATUS:
				return <UrgentStatusTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
