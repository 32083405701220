import { useEffect } from 'react';

// Import utils
import { useDeliveryLocationProvider } from '../../../../context';

// Import helpers
import { DELIVERY_STATUS } from 'helpers';

const { FINISHED } = DELIVERY_STATUS;

export const useLocationContainer = ({ deliveryType, customStepsData }) => {
	const data = useDeliveryLocationProvider();

	const deliveryData = customStepsData ? customStepsData : data[deliveryType];

	const {
		is_real_time,
		isFetching,
		isFetched,
		isError,
		refetch,
		states,
		state,
	} = deliveryData || {};

	const intervalTime = is_real_time ? 10000 : 30000;

	const allowInterval = state !== FINISHED;

	useEffect(() => {
		const timerId = allowInterval && setInterval(refetch, intervalTime);

		return () => clearInterval(timerId);
		//eslint-disable-next-line
	}, [allowInterval, intervalTime]);

	return {
		deliveryData,
		isFetching,
		isFetched,
		isError,
		states,
	};
};
