import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT, CONTRACT_STATUS } from 'helpers';

// Import utils
import { useBookingPreview, useContractData } from 'queries';
import { useProfile } from './useProfile';

export const useEContract = ({ contractNumber, hasCredentials }) => {
	const { OPENED, EXTENDED, SUSPENDED } = CONTRACT_STATUS;

	const { id: bookingUuid } = useParams();

	const {
		data: booking,
		isFetching: bookingFetching,
		isError: bookingError,
	} = useBookingPreview(bookingUuid);

	const {
		data: contractData,
		isFetching: contractDataFetching,
		isError: contractDataError,
	} = useContractData(contractNumber);

	const { isAdmin } = useProfile();

	const { status: contractStatus, suspension_code: suspensionCode } =
		contractData || {};

	const { pick_date: pickDate, pick_time: pickTime } = booking?.period || {};

	const isDateBeforePickUp = () =>
		booking &&
		format(new Date(), DEFAULT_DATE_FORMAT) <=
			format(new Date(pickDate), DEFAULT_DATE_FORMAT);

	const isDateAfterPickUp = () =>
		booking && new Date() >= new Date(`${pickDate} ${pickTime}`);

	const isBeforePickup = isDateBeforePickUp();
	const isAfterPickup = isDateAfterPickUp();

	const isInitiateContractButtonVisible =
		!contractNumber && isBeforePickup && !isAdmin && hasCredentials;

	const isViewContractButtonVisible = !!contractNumber && !isAdmin;

	const isExtendContractButtonVisible =
		!isAdmin &&
		isAfterPickup &&
		(contractStatus === OPENED || contractStatus === EXTENDED);

	const isCloseContractButtonVisible =
		!isAdmin &&
		isAfterPickup &&
		(contractStatus === OPENED ||
			contractStatus === EXTENDED ||
			contractStatus === SUSPENDED);

	const isSuspendContractButtonVisible =
		!isAdmin &&
		isAfterPickup &&
		(contractStatus === OPENED ||
			contractStatus === EXTENDED ||
			(contractStatus === SUSPENDED && suspensionCode === 1));

	return {
		isInitiateContractButtonVisible,
		isSuspendContractButtonVisible,
		isExtendContractButtonVisible,
		isCloseContractButtonVisible,
		isViewContractButtonVisible,
		contractDataFetching,
		contractDataError,
		contractData,
		bookingFetching,
		bookingError,
	};
};
