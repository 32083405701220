import React, { createContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { node } from 'prop-types';
import _ from 'lodash';

// Import utils
import { useEContract, useProfile, useToggle } from 'components/utilities';
import {
	useInitiateContractInitialValues,
	useCreateContractFormOptions,
	useContractFormPrivacyPolicy,
	useExtendedCoverages,
	useContractBranches,
	useVehiclePlates,
	useContractDraftData,
} from 'queries';

// Import helpers
import {
	CONTRACT_STATUS,
	INITIATE_CONTRACT_STEPS,
	removeEmptyValues,
} from 'helpers';
import {
	convertApiInitialValues,
	INITIAL_VALUES,
} from '../../components/DataForm/helpers';

export const InitiateContractContext = createContext(null);

export const InitiateContractProvider = ({ children }) => {
	const { DRAFT } = CONTRACT_STATUS;
	const [step, setStep] = useState(INITIATE_CONTRACT_STEPS.DATA);
	const [filledSections, setFilledSections] = useState([]);
	const { isAdmin } = useProfile();

	const { on: isSearchPlateDialogOpen, toggle: toggleSearchPlateDialog } =
		useToggle(false);

	const { id: bookingUuid } = useParams();

	const {
		data: formOptions,
		isFetching: isFormOptionsFetching,
		isError: isFormOptionsError,
	} = useCreateContractFormOptions();

	const {
		data: branches,
		isFetching: isBranchesFetching,
		isError: isBranchesError,
	} = useContractBranches();

	const {
		data: extendedCoverages,
		isFetching: isExtendedCoveragesFetching,
		isError: isExtendedCoveragesError,
	} = useExtendedCoverages();

	const {
		data: vehiclePlates,
		isFetching: isVehiclePlatesFetching,
		isError: isVehiclePlatesError,
	} = useVehiclePlates(bookingUuid);

	const {
		data: policies,
		isFetching: isPoliciesFetching,
		isError: isPoliciesError,
	} = useContractFormPrivacyPolicy();

	const {
		data: initialValues,
		error: initialError,
		isError: isInitialValuesError,
		isFetching: isInitialValuesFetching,
	} = useInitiateContractInitialValues(bookingUuid);

	const {
		contract_number: contractNumber,
		has_credentials: hasCredentials,
		contract_details: contractDetails,
	} = initialValues || {};

	const { contract_state: contractState } = contractDetails || {};

	const isViewDraftContractButtonVisible =
		!contractNumber && !isAdmin && contractState && contractState === DRAFT;

	const {
		data: draftData,
		isFetching: isDraftDataFetching,
		isError: isDraftDataError,
	} = useContractDraftData({
		uuid: bookingUuid,
		enabled: isViewDraftContractButtonVisible,
	});

	const {
		isInitiateContractButtonVisible: allowInitiateContract,
		bookingError,
		bookingFetching,
	} = useEContract({ contractNumber, hasCredentials });

	const extendedInitialValues = useMemo(() => {
		return Object.entries(INITIAL_VALUES).reduce((acc, [key, values]) => {
			const convertedInitialValues = convertApiInitialValues(
				isViewDraftContractButtonVisible
					? removeEmptyValues(draftData)
					: initialValues
			);
			const apiValues = convertedInitialValues[key];
			const newValues = {
				...values,
				...(_.isObject(apiValues) && apiValues),
			};

			return {
				...acc,
				[key]: newValues,
				plate: convertedInitialValues?.plate || '',
			};
		}, {});
		// eslint-disable-next-line
	}, [initialValues, draftData]);

	const error = initialError?.response?.data?.error;

	const isSectionFilled = (section) => filledSections.includes(section);

	const addSection = (section) => {
		if (!filledSections.includes(section)) {
			setFilledSections([...filledSections, section]);
		}
	};

	const removeSection = (section) => {
		if (filledSections.includes(section)) {
			const filteredSections = filledSections.filter(
				(filteredSection) => filteredSection !== section
			);

			setFilledSections(filteredSections);
		}
	};

	const isFetching =
		isExtendedCoveragesFetching ||
		isVehiclePlatesFetching ||
		isInitialValuesFetching ||
		isFormOptionsFetching ||
		isPoliciesFetching ||
		isBranchesFetching ||
		bookingFetching ||
		isDraftDataFetching;

	const isError =
		isExtendedCoveragesError ||
		isInitialValuesError ||
		isVehiclePlatesError ||
		isFormOptionsError ||
		isPoliciesError ||
		isBranchesError ||
		bookingError ||
		isDraftDataError;

	return (
		<InitiateContractContext.Provider
			value={{
				initialValues: extendedInitialValues,
				allowInitiateContract,
				extendedCoverages,
				filledSections,
				removeSection,
				vehiclePlates,
				formOptions,
				addSection,
				branches,
				policies,
				setStep,
				error,
				step,
				isSectionFilled,
				isFetching,
				isError,
				toggleSearchPlateDialog,
				isSearchPlateDialogOpen,
				bookingUuid,
			}}
		>
			{children}
		</InitiateContractContext.Provider>
	);
};

InitiateContractProvider.propTypes = {
	children: node,
};
